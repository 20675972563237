import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import { FcGoogle } from 'react-icons/fc';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login";


export default class OTP extends Component {

    state = {
        email: '',
        password: '',
        successMessage: '',
        errorMessage: '',
        errorEmail: '',
        errorPassword: '',
        passwordInputType: false
    }


    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    login = async (e) => {
        e.preventDefault();

        // input form validation
        this.inputFieldValidation();
        let email = this.state.email;
        let password = this.state.password;

        if(email === '' || password === '') {
            return false;
        }

        let metaData = { email, password };
        let { data } = await Axios.post(Constant.apiBasePath + 'user/login', metaData);
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            localStorage.setItem('loginDetails', data.data);

            // get user product cart details
            let addToCartDetails = localStorage.getItem('addToCartDetails');
            addToCartDetails = JSON.parse(addToCartDetails);

            // get user buy now product details
            let buyNowDetails = localStorage.getItem('buyNowDetails');
            buyNowDetails = JSON.parse(buyNowDetails);

            if(addToCartDetails) {
                let answer = localStorage.getItem('addToAnuwerCartDetails');
                answer = JSON.parse(answer)
                let metaData = {competitionId: answer.competitionId, userChooseOption: answer.option}
                await Axios.post(Constant.apiBasePath + 'competition/saveUserAnswer', metaData, { headers: { 'token': data.data }});

                let response = await Axios.post(Constant.apiBasePath + 'order/save-in-my-cart', addToCartDetails, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('addToCartDetails');
                    setTimeout(() => { window.location.href = '/cart' }, 3000);
                    return false
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${addToCartDetails.competitionId}` }, 3000);
                    return false
                }
            }
            if(buyNowDetails) {
                let answer = localStorage.getItem('addToAnuwerCartDetails');
                answer = JSON.parse(answer)
                let metaData = {competitionId: answer.competitionId, userChooseOption: answer.option}
                await Axios.post(Constant.apiBasePath + 'competition/saveUserAnswer', metaData, { headers: { 'token': data.data }});

                let response = await Axios.post(Constant.apiBasePath + 'order/buy-now/' + buyNowDetails.id, {}, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('buyNowDetails');
                    this.props.history.push(`/checkout/${buyNowDetails.id}/${buyNowDetails.encryptType}/${buyNowDetails.encryptQuantity}`);
                    return false
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${buyNowDetails.id}` }, 3000);
                    return false
                }
            }
            else {
                setTimeout(() => { window.location.href = '/' }, 3000);
                return false
            }
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    inputFieldValidation = () => {
        let email = this.state.email;
        let password = this.state.password;
        if(email === '') {
            this.setState({ 'errorEmail': 'Email is required!' });
        }
        else {
            this.setState({ 'errorEmail': '' });
        }
       
        if(password === '') {
            this.setState({ 'errorPassword': 'Password is required!' });
        }
        else {
            this.setState({ 'errorPassword': '' });
        }
    }

    mouseOver = () => {
        this.setState({ 'passwordInputType': true });
    }

    mouseOut = () => {
        this.setState({ 'passwordInputType': false });
    }

    responseGoogle =  async (response) => {
        console.log('Google login ', response);
        let tokenId = response.credential;
        let metaData = { 'token': tokenId };
        let { data } = await Axios.post(Constant.apiBasePath + 'auth-google', metaData);
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            localStorage.setItem('loginDetails', data.data.token);

            // get user product cart details
            let addToCartDetails = localStorage.getItem('addToCartDetails');
            addToCartDetails = JSON.parse(addToCartDetails);

            // get user buy now product details
            let buyNowDetails = localStorage.getItem('buyNowDetails');
            buyNowDetails = JSON.parse(buyNowDetails);

            if(addToCartDetails) {
                let answer = localStorage.getItem('addToAnuwerCartDetails');
                answer = JSON.parse(answer)
                let metaData = {competitionId: answer.competitionId, userChooseOption: answer.option}
                await Axios.post(Constant.apiBasePath + 'competition/saveUserAnswer', metaData, { headers: { 'token': data.data }});

                let response = await Axios.post(Constant.apiBasePath + 'order/save-in-my-cart', addToCartDetails, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('addToCartDetails');
                    setTimeout(() => { window.location.href = '/cart' }, 3000);
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${addToCartDetails.competitionId}` }, 3000);
                }
            }
            if(buyNowDetails) {
                let response = await Axios.post(Constant.apiBasePath + 'order/buy-now/' + buyNowDetails.id, {}, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('buyNowDetails');
                    this.props.history.push(`/checkout/${buyNowDetails.id}/${buyNowDetails.encryptType}/${buyNowDetails.encryptQuantity}`);
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${buyNowDetails.id}` }, 3000);
                }
            }
            else {
                setTimeout(() => { window.location.href = '/' }, 3000);
            }
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    responseFacebook =  async (response) => {
        console.log('Facebook login ', response);
        let { name, email, mobile, picture, accessToken } = response;
        let metaData = { 'name': name, 'email': (email) ? email : '', 'mobile': (mobile) ? mobile : '', 'picture': (picture) ? picture : '', 'token': accessToken };
        let { data } = await Axios.post(Constant.apiBasePath + 'auth-facebook', metaData);
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            localStorage.setItem('loginDetails', data.data);

            // get user product cart details
            let addToCartDetails = localStorage.getItem('addToCartDetails');
            addToCartDetails = JSON.parse(addToCartDetails);

            // get user buy now product details
            let buyNowDetails = localStorage.getItem('buyNowDetails');
            buyNowDetails = JSON.parse(buyNowDetails);

            if(addToCartDetails) {
                let response = await Axios.post(Constant.apiBasePath + 'order/save-in-my-cart', addToCartDetails, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('addToCartDetails');
                    setTimeout(() => { window.location.href = '/cart' }, 3000);
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${addToCartDetails.competitionId}` }, 3000);
                }
            }
            if(buyNowDetails) {
                let response = await Axios.post(Constant.apiBasePath + 'order/buy-now/' + buyNowDetails.id, {}, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('buyNowDetails');
                    this.props.history.push(`/checkout/${buyNowDetails.id}/${buyNowDetails.encryptType}/${buyNowDetails.encryptQuantity}`);
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${buyNowDetails.id}` }, 3000);
                }
            }
            else {
                setTimeout(() => { window.location.href = '/' }, 3000);
            }
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }
    
    
    render() {

        return(
            <>
           <section className="wrap">
              <div className="container">
                  <div className="form-box form-box-login">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                            <div className="login-white-box">
                                <div className="title text-center">
                                  <h3 className="mb-3">Welcome to <span className="text-yellow">Ro Competition</span></h3>
                                    <p>Changing the way you use a contact book.</p>
                                </div>
                                <div className="signp-with-social">
                                    <div className="">
                                        <FacebookLogin
                                            appId="258237673911615"
                                            callback={this.responseFacebook}
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn"><i className='fa fa-facebook'></i>Log in with Facebook</button>
                                            )}
                                        />
                                    </div>
                                  {/* <a href="#"><img src="images/fb-icon.png"/> <span>Facebook</span></a> */}
                                  {/* <a href="#"><img src="images/google-icon.png"/> <span>Google</span></a> */}
                                    <div className="">
                                        <GoogleOAuthProvider 
                                            clientId="878744637320-2kc66crqo0qan599jbfgp705cktk1g4k.apps.googleusercontent.com"
                                            >
                                        <GoogleLogin
                                        render={(renderProps) => (
                                            <button
                                            type="button"
                                            className=""
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                            >
                                            <FcGoogle className="" /> Sign in with google
                                            </button>
                                        )}
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        />
                                        </GoogleOAuthProvider>
                                    </div>
                                </div>
                                <div className="or"><span>Or</span></div>

                                {
                                    (this.state.successMessage)
                                    ?
                                    <p className="text-success">{ this.state.successMessage }</p>
                                    :
                                    (this.state.errorMessage)
                                    ?
                                    <p className="text-danger"> { this.state.errorMessage }</p>
                                    :
                                    ""
                                }
                        
                                <form>
                                    <div className="form-group">
                                        <input type="email" id="email" onChange={this.handleChange} className="form-ctrl form-control" placeholder="Email Id"/> 
                                        <p className='text-danger'>{ this.state.errorEmail }</p>
                                    </div>
                                    <div className="form-group">
                                        <input type={ (this.state.passwordInputType) ? 'text' : 'password' } id="password" onChange={this.handleChange} className="form-control form-ctrl" placeholder="Enter Password"/>  
                                        <p className='text-danger'>{ this.state.errorPassword }</p>
                                        <div className="form_icon">
                                             <span toggle="#password" className="fa fa-fw field-icon toggle-password fa-eye" onMouseOver={ this.mouseOver } onMouseOut={ this.mouseOut }></span></div> 
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required=""/>
                                                        <label className="form-check-label label-trms" for="invalidCheck">
                                                          Remember me
                                                        </label>
                                                      <div className="invalid-feedback">
                                                    You must agree before submitting.
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          <div className="col-6 col-sm-6 col-md-6 text-end">
                                              <div className="form-group forgot-txt">
                                                  <p><Link to="/forgot-password"> Forgot Password?</Link>
                                                  </p>
                                              </div>
                                              </div>
                                          </div>
                          
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-warning btn-gradent btn-block btn-sumbit" onClick={this.login}>Login</button>
                                            </div>
                        
                                            <div className="form-group forgot-txt SignUp-txt text-center">
                                              <p>New to Ro Competition? <Link to="/register"> Register</Link></p>
                                          </div>
                                    </form>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </section>
            </>
        )
    }
}