import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import $ from 'jquery';


export default class Card extends Component {

    state = {
        OrderList: [],
        successMessage: '',
        errorMessage: ''
    }

    // get my carts

    myOrders = async (id) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'order/myTransaction', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'OrderList': data.data.transactions });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        this.myOrders();
    }


    render() {
        let { OrderList } = this.state;
      
        let orders = [];

        if(OrderList.length > 0) {
          let i = 0;
            orders = OrderList.map(el => {
              i++;
              return <div className="table_row">
                          
                      <div className="table_small">
                        <div className="table_cell">S.No</div>
                        <div className="table_cell">{ i }</div>
                      </div>
                      <div className="table_small dashboradtitle">
                        <div className="table_cell">Order No.</div>
                        <div className="table_cell pro-img-table">{ el.orderId }</div>
                      </div>
                      <div className="table_small dashboradtitle">
                        <div className="table_cell">Product</div>
                        <div className="table_cell pro-img-table">
                            <h6><img className="rounded" src={ el.images[0] } alt="" style={{ width: 50, height: 40 }}/>{ el.competitionName }</h6>
                        </div>
                      </div>
                      <div className="table_small">
                  <div className="table_cell">Paid Amount </div>
                  <div className="table_cell"><div>{ el.competitionAmount }</div>
                     </div>
                </div>
                {
                  (el.status === 2)
                  ?
                    <div className="table_small">
                        <div className="table_cell">Payment Status </div>
                        <div className="table_cell"><div className="successGreenBorder">Success</div>
                        </div>
                    </div>
                  :
                  <div class="table_small">
                    <div class="table_cell">Payment Status </div>
                      <div class="table_cell"><div class="redBorder">Fail</div>
                      </div>
                  </div>
                
                }
                <div className="table_small">
                  <div className="table_cell">Date</div>
                  <div className="table_cell">{ el.createdAt }</div>
                </div>
                <div className="table_small">
                  <div className="table_cell">&nbsp;</div>
                     <div className="table_cell">
                      <div className="orderbtn_main_butt">
                        <Link to={`/order-details/${el._id}`} className="btn btn-warning btn-sm">View</Link>
                      </div>
                     </div>
                </div>
                </div>
            })
        }
        return(
            <>
        <section className="inr-wrap">
    <div className="container-fluid">
	<div className="breadcrumb-heading">
        <nav aria-label="breadcrumb">
    <ol className="brdcrm breadcrumb">
        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
        <li className="breadcrumb-item active">My Order</li>
        </ol>
        </nav>
    </div>
    </div>
    </section>
    <section className="wrap user-dashboard">
    <div className="container-fluid">
        <div className="page-header">
                 <div className="row">
                     <div className="col-6 col-sm-7 col-md-8 col-lg-9 col-xl-10">
                     <div className="d-inline-block">
                        <h3>My Order</h3>
                    <div className="bdr-btm-heading"></div>
                    </div>
                     </div>
                     <div className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2">
                     <div className="form-group searchgroup mb-0">
                    <input type="text" className="form-control form-ctrl" placeholder="Search OrderList"/>
                    <button type="submit" className="btn btn-link"><i className="fa fa-search"></i></button>
                </div>
                     </div>
                     </div>
                 
                 </div>
       <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="account_dashboard my_OrderList_dashboard">
      <div className="login-white-box">
          <div className="OrderList_table_main">
              <div className="table-responsive">
                  <div className="table">
                      <div className="theader">
                          <div className="table_header">S.No</div>
                          <div className="table_header">Order No.</div>
                          <div className="table_header dashboradtitle">Product</div>
                          <div className="table_header">Paid Amount  </div>
                          <div className="table_header">Payment Status  </div>
                          <div className="table_header">Date</div>
                          <div className="table_header  width12">Action</div>
                      </div>

                      { orders }

                  </div>
                </div>
          </div>
        
         </div>
      </div>     
      </div>
      </div>
		</div>
    </section>

<div className="modal fade" id="ratingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content feedback-popup">
        <div className="modal-header justify-content-center">
          <h5 className="modal-title text-center" id="exampleModalLabel">Rating &amp; Review </h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
        <div className="form-box-login">
            <div className="rating-stars">
                                                    <i className="fa fa-star checked"></i>
                                                    <i className="fa fa-star checked"></i>
                                                    <i className="fa fa-star checked"></i>
                                                    <i className="fa fa-star checked"></i>
                                                    <i className="fa fa-star"></i>
                                                    </div>
         
            <div className="form-box">
            <div className="form-group">
                <label>Your feedback</label>
              <textarea className="form-control form-ctrl" rows="6"></textarea>
              </div>
               <button type="submit" className="btn btn-dark btn-block">Submit</button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
            </>
        )
    }
}