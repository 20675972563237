import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';

export default class AddUpdateAddress extends Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        countryId: '',
        cityId: '',
        street: '',
        postCode: '',
        mobile: '',
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        companyNameError: '',
        countryError: '',
        cityError: '',
        streetError: '',
        postCodeError: '',
        mobileError: '',
        UserAddress: {},
        CountryList: [],
        CityList: [],
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        if(e.target.id === 'countryId') {
            this.getCities(e.target.value.split("_")[0]);
        }
    }

    getUserAddress = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user/getAddress', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.getCities(data.data.countryId);
            this.setState({ 'UserAddress': data.data });
        }
        else {
            // this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    getCountries = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'country/getDataForDropdown', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CountryList': data.data });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    getCities = async (countryId) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'city/getDataForDropdown/' + countryId, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CityList': data.data });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    cancelAddress = () => {
        window.location.reload();
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let token = localStorage.getItem('loginDetails');
        let UserAddress = this.state.UserAddress;
        let id = this.props.match.params.id;

        let metaData = {
            'firstName': this.state.firstName,
            'lastName': this.state.lastName,
            'email': this.state.email,
            'companyName': this.state.companyName,
            'countryId': (this.state.countryId) ? this.state.countryId.split("_")[0] : "",
            'cityId': (this.state.cityId) ? this.state.cityId.split("_")[0] : "",
            'countryName': (this.state.countryId) ? this.state.countryId.split("_")[1] : "",
            'cityName': (this.state.cityId) ? this.state.cityId.split("_")[1] : "",
            'street': this.state.street,
            'postCode': this.state.postCode,
            'mobile': this.state.mobile
        }

        if(id === undefined) {
            // input field validation
            this.inputFieldValidation();
        }

        if(id) {
            metaData = {
                'id': id,
                'firstName': (this.state.firstName) ? this.state.firstName : UserAddress.firstName,
                'lastName': (this.state.lastName) ? this.state.lastName : UserAddress.lastName,
                'email': (this.state.email) ? this.state.email : UserAddress.email,
                'companyName': (this.state.companyName) ? this.state.companyName : '',
                'countryId': (this.state.countryId) ? this.state.countryId.split("_")[0] : UserAddress.countryId,
                'cityId': (this.state.cityId) ? this.state.cityId.split("_")[0] : UserAddress.cityId,
                'countryName': (this.state.countryId) ? this.state.countryId.split("_")[1] : UserAddress.countryName,
                'cityName': (this.state.cityId) ? this.state.cityId.split("_")[1] : UserAddress.cityName,
                'street': (this.state.street) ? this.state.street : UserAddress.street,
                'postCode': (this.state.postCode) ? this.state.postCode : UserAddress.postCode,
                'mobile': (this.state.mobile) ? this.state.mobile : UserAddress.mobile.toString()
            }
        }

        if((metaData.firstName === '' || metaData.lastName === '' || metaData.email === '' ||
            metaData.countryId === '' || metaData.cityId === '' || metaData.street === '' || metaData.postCode === '' ||
            metaData.mobile === '') && id === undefined) {
            return false;
        }
        
        let { data } = await Axios.post(Constant.apiBasePath + 'user/addUpdateAddress', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(() => { window.location.href = '/profile' }, 3000);
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    inputFieldValidation = () => {
        let firstName = this.state.firstName;
        let lastName = this.state.lastName;
        let email = this.state.email;
        let companyName = this.state.companyName;
        let countryId = this.state.countryId;
        let cityId = this.state.cityId;
        let street = this.state.street;
        let postCode = this.state.postCode;
        let mobile = this.state.mobile;

        if(firstName === '') {
            this.setState({ 'firstNameError': 'FirstName is required!' });
        }
        else {
            this.setState({ 'firstNameError':'' });
        }

        if(lastName === '') {
            this.setState({ 'lastNameError': 'LastName is required!' });
        }
        else {
            this.setState({ 'lastNameError':'' });
        }

        if(email === '') {
            this.setState({ 'emailError': 'Email is required!' });
        }
        else {
            this.setState({ 'emailError':'' });
        }

        if(countryId === '') {
            this.setState({ 'countryError': 'Please select country!' });
        }
        else {
            this.setState({ 'countryError':'' });
        }

        if(cityId === '') {
            this.setState({ 'cityError': 'Please select city!' });
        }
        else {
            this.setState({ 'cityError':'' });
        }

        if(street === '') {
            this.setState({ 'streetError': 'Street is required!' });
        }
        else {
            this.setState({ 'streetError':'' });
        }

        if(postCode === '') {
            this.setState({ 'postCodeError': 'Postcode is required!' });
        }
        else {
            this.setState({ 'postCodeError':'' });
        }

        if(mobile === '') {
            this.setState({ 'mobileError': 'Mobile is required!' });
        }
        else {
            this.setState({ 'mobileError':'' });
        }
    }

    componentDidMount() {
        this.getUserAddress();
        this.getCountries();
    }
    
    render() {

        let { UserAddress, CountryList, CityList } = this.state;

        let firstName = '';
        let lastName = '';
        let email = '';
        let companyName = '';
        let countryId = '';
        let cityId = '';
        let street = '';
        let postCode = '';
        let mobile = '';
        let countryOptions = [];
        let cityOptions = [];
        let heading = 'Add Address';
        let btnValue = 'Save';

        if(UserAddress.firstName !== undefined) {
            firstName = UserAddress.firstName;
            lastName = UserAddress.lastName;
            email = UserAddress.email;
            companyName = UserAddress.companyName;
            countryId = UserAddress.countryId;
            cityId = UserAddress.cityId;
            street = UserAddress.street;
            postCode = UserAddress.postCode;
            mobile = UserAddress.mobile;
            heading = 'Update Address';
            btnValue = 'Update';

            countryOptions = CountryList.map(el => {
                let value = el._id + '_' + el.name;
                if(el._id === countryId) {
                    return <option id={el._id} value={value} selected>{el.name}</option>
                }
                else {
                    return <option id={el._id} value={value}>{el.name}</option>
                }
            });

            cityOptions = CityList.map(el => {
                let value = el._id + '_' + el.name;
                if(el._id === cityId) {
                    return <option id={el._id} value={value} selected>{el.name}</option>
                }
                else {
                    return <option id={el._id} value={value}>{el.name}</option>
                }
            })

        }
        else {
            if(CountryList.length > 0) {
                countryOptions = CountryList.map(el => {
                    let value = el._id + '_' + el.name;
                    return <option id={el._id} value={value}>{el.name}</option>
                })
            }

            if(CityList.length > 0) {
                cityOptions = CityList.map(el => {
                    let value = el._id + '_' + el.name;
                    return <option id={el._id} value={value}>{el.name}</option>
                })
            }
        }


        return(
            <>
               <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/profile">My Account</Link></li>
                                    <li className="breadcrumb-item active">{ heading }</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="page-header">
                            <h3>{ heading }</h3>
                            <div className="bdr-btm-heading"></div>
                        </div>
                        <div className="account_dashboard">
                            <div className="form-box">
                                <form>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" id="firstName" onChange={ this.handleChange } defaultValue={ firstName } className="form-ctrl form-control" placeholder="First Name*"/>
                                                <p className='text-danger'>{ this.state.firstNameError }</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" id="lastName" onChange={ this.handleChange } defaultValue={ lastName }  className="form-ctrl form-control" placeholder="Last Name*"/>
                                                <p className='text-danger'>{ this.state.lastNameError }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input type="email" id="email" onChange={ this.handleChange } defaultValue={ email }  className="form-ctrl form-control" placeholder="Email Id"/>
                                                <p className='text-danger'>{ this.state.emailError }</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <select className="form-ctrl form-control" id="countryId" onChange={ this.handleChange }>
                                                    <option id="0" value="0">Select Country</option>
                                                    { countryOptions }
                                                </select>
                                                <p className='text-danger'>{ this.state.countryError }</p>
                                            </div>
                                        </div>
                                       {/* <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" id="companyName" onChange={ this.handleChange } defaultValue={ companyName } className="form-ctrl form-control" placeholder="Company Name"/>
                                                <p className='text-danger'>{ this.state.companyNameError }</p>
                                            </div>
                                        </div> */}
                                    </div>
                                        
                                    <div className='row'>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <select className="form-ctrl form-control" id="cityId" onChange={ this.handleChange }>
                                                    <option id="0" value="0">Select City</option>
                                                    { cityOptions }
                                                </select>
                                                <p className='text-danger'>{ this.state.cityError }</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" id="street" onChange={ this.handleChange } defaultValue={ street } className="form-ctrl form-control" placeholder="Street"/>
                                                <p className='text-danger'>{ this.state.streetError }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" id="mobile" onChange={ this.handleChange } defaultValue={ mobile } className="form-ctrl form-control" placeholder="Phone Number"/>
                                                <p className='text-danger'>{ this.state.mobileError }</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input type="text" id="postCode" onChange= { this.handleChange } defaultValue={ postCode } className="form-ctrl form-control" placeholder="EIR Code/ Post Code"/>
                                                <p className='text-danger'>{ this.state.postCodeError }</p>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="row mt-10">
                                        {
                                            (this.state.successMessage)
                                            ?
                                               <p className='text-success'>{ this.state.successMessage }</p>
                                            :
                                               null
                                        }
                                        {
                                            (this.state.errorMessage)
                                            ?
                                               <p className='text-danger'>{ this.state.errorMessage }</p>
                                            :
                                               null
                                        }

                                        <div className="col-5 col-sm-3 col-md-3 col-lg-3">
                                            <button type="submit" className="btn btn-light btn-block btn-sumbit" onClick={ this.cancelAddress }>Cancel</button>
                                        </div>
                                        <div className="col-5 col-sm-3 col-md-3 col-lg-3">
                                            <button type="submit" className="btn btn-warning btn-block btn-sumbit" onClick={ this.handleSubmit }>{ btnValue }</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}