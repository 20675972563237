import React, { Component } from 'react';

export default class Feedback extends Component {
    
    render() {
        return(
            <>
             <section class="wrap-40 bg-gray">
    <div class="container">
        
            <div class="form-box form-box-login">
                <div class="row">
                <div class="col-12 col-sm-12 col-md-6 wlcome-login">
                    <img src="images/rating-bnr.jpg"/>
                    </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="login-white-box">
                        <div class="title text-center">
                            <h2 class="rating-stars">
                                                    <i class="fa fa-star checked"></i>
                                                    <i class="fa fa-star checked"></i>
                                                    <i class="fa fa-star checked"></i>
                                                    <i class="fa fa-star checked"></i>
                                                    <i class="fa fa-star"></i>
                                                    </h2>
                        <h3> Your Feedback</h3>
                            
                        </div>
                        
                <form action="#">
                    <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
          <label>Choose who you are</label>
          <select class="form-ctrl form-control">
                        <option>--Select--</option>
                        <option>Customer</option>
                        <option>Employee</option>
                        <option>Partner</option>
                        <option>Vendor</option>
                        <option>Other</option>
                        </select>     
          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
          <label>What made is great?</label>
          <select class="form-ctrl form-control">
                        <option>--Select--</option>
                        <option>Delivery</option>
                        <option>Product</option>
                        <option>Payment</option>
                        <option>Website</option>
                        <option>Installation</option>
                        <option>Call Center</option>
                        </select>     
          </div>
                        </div>
                    </div>
                    <div class="form-group">
                <label>Your Name</label>
                <input type="text" class="form-ctrl form-control" placeholder="Type here"/>      
                </div>
                    <div class="row">
                   
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
            <label>Mobile</label>
             <div class="number-code">
                    <div class="city-code-ctrl">
                    <select class="form-ctrl form-control">
                    <option>+91</option>
                    </select>
                        </div>
                        <input type="text" class="form-ctrl form-control" placeholder=""/>
                    </div>
                
            </div>
                        </div>
                         <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                <label>Email Id</label>
                    <input type="text" class="form-ctrl form-control" placeholder="Type here"/>    
              </div>
                        </div>
                    </div>
              
              
                    
          
                    
            <div class="form-group">
          <label>Comments</label> 
                <textarea rows="4" class="form-ctrl form-control" placeholder="Type here"></textarea>
          </div>
          <div class="form-group">
           <button type="submit" class="btn btn-dark btn-block">Submit</button>
           </div>
           
        </form>
                        
                    </div>
                    </div>
                </div>
        </div>
        
        </div>
    </section>
            </>
        )
    }
}