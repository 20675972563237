import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';


export default class Notification extends Component {

    state = {
        DataList: [],
        pageNo: 1,
        length: 0,
        count: 0,
        successMessage: '',
        errorMessage: ''
    }

    // get notification list

    userNotification = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user-notification?readStatus=1', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'DataList': data.data.list, 'count': data.data.count, 'length': data.data.length });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    componentDidMount() {
        this.userNotification(this.state.pageNo);
    }


    render() {
        let { DataList, pageNo, length } = this.state;
      
        let notifications = [];

        if(DataList.length > 0) {
            notifications = DataList.map(el => {
            return <div className="notification-item">
                        <div className="row">
                            <div className="col-8 col-sm-8 col-md-9">
                                <h5>{ el.title }</h5>
                            </div>
                            {
                                (el.readStatus)
                                ?
                                   null
                                :
                                    <div className="col-4 col-sm-4 col-md-3 text-end">
                                        <a href="#" className="btn btn-warning">New</a>
                                    </div>
                            }
                        </div>
                        <p>{ el.description }</p>
                        <p className="notification-date"><i className="fa fa-clock-o"></i>{ el.createdAt }</p>
                    </div>
            });
        }

        console.log(notifications)

        return(
            <>
                <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Notification</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="page-header">  
                        <div className="d-inline-block">
                            <h3>Notification</h3>
                            <div className="bdr-btm-heading"></div>
                            </div>
                        </div>
                        <div className="notification-list">
                            { notifications }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}