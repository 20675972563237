import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';


export default class EditProfile extends Component {

    state = {
        firstName: '',
        lastname: '',
        email: '',
        countryId: '',
        cityId: '',
        userProfile: '',
        UserDetails: {},
        CountryList: [],
        CityList: [],
        newEmail: '',
        modal: 1,
        token: '',
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        counter: 45,
        countryError: '',
        cityError: '',
        newEmailError: '',
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        if(e.target.id === 'countryId') {
            this.getCities(e.target.value.split('_')[0]);
        }
    }

    handleImage = (e) => {
        this.setState({ [e.target.id]: e.target.files[0] });
    }

    getProfileDetails = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user/getProfileDetails', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            if(data.data.countryId) {
                this.getCities(data.data.countryId);
            }
            this.setState({ 'UserDetails': data.data });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    getCountries = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'country/getDataForDropdown', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CountryList': data.data });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    getCities = async (countryId) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'city/getDataForDropdown/' + countryId, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CityList': data.data });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        // input form validation
        this.inputFieldValidation();
        let token = localStorage.getItem('loginDetails');
        let UserDetails = this.state.UserDetails;

        if((this.state.countryId === '' && UserDetails.countryId === undefined) || (this.state.cityId === '' && UserDetails.cityId === undefined)) {
            return false;
        }

        const form_data = new FormData();

        if(this.state.userProfile) {
            form_data.append('userProfile', this.state.userProfile, this.state.userProfile.name);
        }

     
        (this.state.firstName) ? form_data.append('firstName', this.state.firstName) : form_data.append('firstName', UserDetails.firstName);
        (this.state.lastname) ? form_data.append('lastname', this.state.lastname) : form_data.append('lastname', UserDetails.lastname);
        (this.state.countryId) ? form_data.append('countryId', this.state.countryId.split('_')[0]) : form_data.append('countryId', UserDetails.countryId);
        (this.state.cityId) ? form_data.append('cityId', this.state.cityId.split('_')[0]) : form_data.append('cityId', UserDetails.cityId);
        (this.state.countryId) ? form_data.append('countryName', this.state.countryId.split('_')[1]) : form_data.append('countryName', UserDetails.countryName);
        (this.state.cityId) ? form_data.append('cityName', this.state.cityId.split('_')[1]) : form_data.append('cityName', UserDetails.cityName);
        let { data } = await Axios.patch(Constant.apiBasePath + 'user/updateProfileDetails', form_data, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(() => { window.location.href = '/profile' }, 3000);
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    inputFieldValidation = () => {
        let countryId = this.state.countryId;
        let cityId = this.state.cityId;
        let userDetails = this.state.UserDetails;
        if(countryId === '' && userDetails.countryId === undefined) {
            this.setState({ 'errorSelectCountry': 'Please select country!' });
        }
        else {
            this.setState({ 'errorSelectCountry': '' });
        }
       
        if(cityId === '' && userDetails.cityId === undefined) {
            this.setState({ 'errorSelectCity': 'Please select city!' });
        }
        else {
            this.setState({ 'errorSelectCity': '' });
        }
    }

    sendOTP = async (event) => {
        event.preventDefault();
        let token = localStorage.getItem('loginDetails');

        let UserDetails = this.state.UserDetails;
        let newEmail = this.state.newEmail;
        if(newEmail === '') {
            this.setState({ 'newEmailError': 'email is required!' });
            return false;
        }
        else {
            this.setState({ 'newEmailError': '' });
        }

        let metaData = {
            'oldEmail': UserDetails.email,
            'newEmail': newEmail
        }

        let { data } = await Axios.post(Constant.apiBasePath + 'user/sendOTPForChangeEmail', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {

            let interval = setInterval(() => { 
                if(this.state.counter > 0) {
                    this.setState({ 'counter': (this.state.counter - 1) })
                }
                else {
                    clearInterval(interval);
                } 
            }, 1000);

            this.setState({ 'successMessage': data.message, 'modal': 2, 'token': data.data });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    resendOTP = async (event) => {
        let token = localStorage.getItem('loginDetails');

        let UserDetails = this.state.UserDetails;
        let metaData = {
            'oldEmail': UserDetails.email,
            'newEmail': this.state.newEmail
        }

        let { data } = await Axios.post(Constant.apiBasePath + 'user/sendOTPForChangeEmail', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            let interval = setInterval(() => { 
                if(this.state.counter > 0) {
                    this.setState({ 'counter': (this.state.counter - 1) })
                }
                else {
                    clearInterval(interval);
                } 
            }, 1000);
            this.setState({ 'successMessage': data.message, 'token': data.data, counter: 45 });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    verifyOTP = async (event) => {
        event.preventDefault();
        let token = localStorage.getItem('loginDetails');
        let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;
        let metaData = {
            'token': this.state.token,
            'otp': otp
        }

        let { data } = await Axios.patch(Constant.apiBasePath + 'user/updateEmail', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(() => { window.location.href = '/login' }, 3000);
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getProfileDetails();
        this.getCountries();
    }
    
    render() {
        let { UserDetails, CountryList, CityList, modal } = this.state;

        let profilePic = (UserDetails.profilePic) ? UserDetails.profilePic : '/images/user-pic.png';
        let firstName = '';
        let lastname = '';
        let email = '';
        let countryId = '';
        let cityId = '';
        let countryOptions = [];
        let cityOptions = [];

        if(UserDetails.email !== undefined) {
            console.log(UserDetails)
            firstName = UserDetails.firstName;
            lastname = UserDetails.lastname;
            email = UserDetails.email;
            countryId = UserDetails.countryId;
            cityId = UserDetails.cityId;

            countryOptions = CountryList.map(el => {
                let value = el._id + '_' + el.name;
                if(el._id === countryId) {
                    return <option id={el._id} value={ value } selected>{el.name}</option>
                }
                else {
                    return <option id={el._id} value={value}>{el.name}</option>
                }
            });

            cityOptions = CityList.map(el => {
                let value = el._id + '_' + el.name;
                if(el._id === cityId) {
                    return <option id={el._id} value={value} selected>{el.name}</option>
                }
                else {
                    return <option id={el._id} value={value}>{el.name}</option>
                }
            })

        }
        else {
            if(CountryList.length > 0) {
                countryOptions = CountryList.map(el => {
                    let value = el._id + '_' + el.name;
                    return <option id={el._id} value={value}>{el.name}</option>
                })
            }

            if(CityList.length > 0) {
                cityOptions = CityList.map(el => {
                    let value = el._id + '_' + el.name;
                    return <option id={el._id} value={value}>{el.name}</option>
                })
            }
        }

        function openModel() {
            window.$("#myModal").modal('show');
        }

        function closeModel() {
            window.$("#myModal").modal('hide');
        }

        /* OTP input fields validatation */

        var inputQuantity = [];
        $(function() {     
            $(".form-ctrl-otp").on("keyup", function (e) {
                var $field = $(this),
                    val=this.value,
                    $thisIndex=parseInt($field.data("idx"),10); 
                if (this.validity && this.validity.badInput || isNaN(val) || $field.is(":invalid") ) {
                    this.value = inputQuantity[$thisIndex];
                    return;
                } 
                if (val.length > Number($field.attr("maxLength"))) {
                    val=val.slice(0, 1);
                    $field.val(val);
                }
                inputQuantity[$thisIndex]=val;
            });      
        });
 
        $(document).ready(function(){
            $("#otp1").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp2").focus();
                }
                else {
                    $("#otp1").focus();
                }
            });

            $("#otp2").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp3").focus();
                }
                else {
                    $("#otp1").focus();
                }
            });

            $("#otp3").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp4").focus();
                }
                else {
                    $("#otp2").focus();
                }
            });

            $("#otp4").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp5").focus();
                }
                else {
                    $("#otp3").focus();
                }
            });
        });
 
        /* End */ 

        return(
            <>
                <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/profile">My Account</Link></li>
                                    <li className="breadcrumb-item active">Edit Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap user-dashboard">
                    <div className="container-fluid">
                        <div className="page-header border-0 pb-0">
                            <h2>Edit Profile</h2>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="profile_dashboard">
                                    <div className="name_info media">
                                        <div className="media-left">
                                            <div className="profile-pic">
                                                <div className="upload-doc-item">
                                                    <div className="image-upload">
                                                        <input type="file" id="userProfile" name="" onChange={ this.handleImage }/>
                                                        <label for="userProfile" className="upload-field" id="file-label">
                                                        <div className="file-thumbnail">
                                                            <img id="file-image-preview" src={ profilePic } alt=""/>
                                                            <h6 id="filename"></h6>
                                                            <div className="edit-pic"><i className="fa fa-pencil"></i></div>
                                                        </div>
                                                    </label>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <h4>{ UserDetails.name }</h4>
                                            {/* <p>User ID: { UserDetails._id }</p> */}
                                        </div>
                                    </div>
                                    <div className="account_dashboard">
                                        <div className="form-box">
                                            <form>
                                                <div className="row">
                                                    <div className="col-sm-3 col-md-3">
                                                        <div className="form-group">
                                                            <input type="text" id="firstName" onChange={ this.handleChange } defaultValue={ firstName } className="form-ctrl form-control" placeholder="First Name*"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 col-md-3">
                                                        <div className="form-group">
                                                            <input type="text" id="lastname" onChange={ this.handleChange } defaultValue={ lastname } className="form-ctrl form-control" placeholder="Last Name*"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <input type="email" id="email" onChange={ this.handleChange } value={ email }  className="form-ctrl form-control" placeholder="Email*" disabled/>
                                                            <button type="button" className="Change-btn btn btn-warning" onClick={ openModel }>Change</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                                <div className='row'>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <select className="form-ctrl form-control" id="countryId" onChange={ this.handleChange }>
                                                                <option id="0" value="0">Select Country</option>
                                                                { countryOptions }
                                                            </select>
                                                            <p className='text-danger'>{ this.state.errorSelectCountry }</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <select className="form-ctrl form-control" id="cityId" onChange={ this.handleChange }>
                                                                <option id="0" value="0">Select City</option>
                                                                { cityOptions }
                                                            </select>
                                                            <p className='text-danger'>{ this.state.errorSelectCity }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div className="row mt-10">
                                                    {
                                                        (this.state.successMessage)
                                                        ?
                                                        <p className='text-success'>{ this.state.successMessage }</p>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        (this.state.errorMessage)
                                                        ?
                                                        <p className='text-danger'>{ this.state.errorMessage }</p>
                                                        :
                                                        null
                                                    }

                                                    <div className="col-5 col-sm-3 col-md-3 col-lg-3">
                                                        <button type="submit" className="btn btn-warning btn-block btn-sumbit" onClick={ this.handleSubmit }>Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>

                    {/** start modal */}

                    <div class="modal" id="myModal">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">
                                        {
                                            (modal === 1)
                                            ?
                                            'Change Email'
                                            :
                                            'Verify OTP'
                                        }
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal" onClick={ closeModel }>&times;</button>
                                </div>
                                <div class="modal-body">
                                     <form>
                                        {
                                            (modal === 1)
                                            ?
                                                <>
                                                    <div className='row'>
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group">
                                                                <input type="email" id="newEmail" onChange={ this.handleChange }  className="form-ctrl form-control" placeholder="Email*"/>
                                                                <p className='text-danger'>{ this.state.newEmailError }</p>
                                                                {
                                                                    (this.state.successMessage)
                                                                    ?
                                                                    <p className='text-success'>{ this.state.successMessage }</p>
                                                                    :
                                                                    null
                                                                }
                                                                {
                                                                    (this.state.errorMessage)
                                                                    ?
                                                                    <p className='text-danger'>{ this.state.errorMessage }</p>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-5 col-sm-3 col-md-3 col-lg-3">
                                                            <button type="submit" className="btn btn-warning btn-block btn-sumbit" onClick={ this.sendOTP }>Send OTP</button>
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                <div className="form-box form-box-login otp-box">
                                                    <div className="title text-center">
                                                        <p>Enter 4 digit OTP code sent to your Email Id </p>
                                                        {
                                                            (this.state.successMessage)
                                                            ?
                                                            <p className="text-success">{ this.state.successMessage }</p>
                                                            :
                                                            (this.state.errorMessage)
                                                            ?
                                                            <p className="text-danger"> { this.state.errorMessage }</p>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                    <form>
                                                        <div className="form-group">
                                                            <input type="number" id="otp1" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                                            <input type="number" id="otp2" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                                            <input type="number" id="otp3" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                                            <input type="number" id="otp4" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                                        </div>
                                        
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-warning btn-gradent btn-block btn-sumbit" onClick={this.verifyOTP}>Verify </button>
                                                        </div>
                                                        <div className="form-group resend-otp text-center">
                                                            <p> <span id="timer">{ this.state.counter }</span></p>
                                                        </div>
                                                        <div className="form-group forgot-txt SignUp-txt text-center">
                                                            <p>Didn't receive OTP?  <button type="button" className="btn btn-success" disabled={ this.state.counter === 0 ? "" : "disabled" } onClick={()=> this.resendOTP() }> Resend</button></p>
                                                        </div>
                                                    </form>   
                                                </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    { /** end modal */}

                </section>
            </>
        )
    }
}