import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import moment from 'moment';


export default class OrderDetails extends Component {

    state = {
        OrderDetails: {},
        successMessage: '',
        errorMessage: ''
    }

    // get contact details

    orderDetails = async (id) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'order/get-details/' + id, { headers: { 'token': token } });
        if (data.status === Constant.statusSuccess) {
            this.setState({ 'OrderDetails': data.data[0] });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }


    componentDidMount() {
        let id = this.props.match.params.id;
        this.orderDetails(id);
    }

    render() {

        let { OrderDetails } = this.state;
        let winnerTickets = [];

        let statusDetails = (OrderDetails.status === 2) ? <span class="text-success">Success</span> : <span class="text-danger">Failure</span>;
        let numberOfItems = (OrderDetails.orderitems) ? OrderDetails.orderitems.length : 0;
        let orderItems = (OrderDetails.orderitems) ? OrderDetails.orderitems : [];

        if(OrderDetails.winners) {
            winnerTickets = OrderDetails.winners.map(el => {
                return el.ticketNumber.toString() + ",";
            });
            
            if(winnerTickets.length > 0) {
                let last_index = winnerTickets.length - 1;
                let last_value = winnerTickets[last_index].replace(',', '');
                winnerTickets[last_index] = last_value;
            }
        }

        let name = '';
        let mobile = '';
        let email = '';
        let address = '';

        if(OrderDetails.useraddresses) {
            if(OrderDetails.useraddresses.length > 0) {
                name = OrderDetails.useraddresses[0].firstName + '' + OrderDetails.useraddresses[0].lastName;
                mobile = (OrderDetails.useraddresses) ? OrderDetails.useraddresses[0].mobile : '';
                email = (OrderDetails.useraddresses) ? OrderDetails.useraddresses[0].email : '';
                address = (OrderDetails.useraddresses) ? OrderDetails.useraddresses[0].street + ' ' +  OrderDetails.useraddresses[0].cityName : '';
            }
        } 

        let orderItemsCards = [];

        if(orderItems.length > 0) {
            orderItemsCards = orderItems.map(el => {
                let tickets = [];
                tickets = el.tickets.map(el => {
                    return el.ticketNumber.toString() + ",";
                });

                let last_index = tickets.length - 1;
                let last_value = tickets[last_index].replace(',', '');
                tickets[last_index] = last_value;

                return <div className="order-item-media">
                            <div className="row">
                                <div className="col-12 col-sm-4 col-md-4 col-lg-3">
                                    <div className="thumbnail tmb">
                                        <img src={ el.competitionImages[0] } alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                                    <div className="row">
                                        <div className="col-9 col-sm-9 col-md-9 col-lg-10 pe-0">
                                            <h4>{ el.competitionTitle }</h4>
                                        </div>
                                        <div className="col-3 col-sm-3 col-md-3 col-lg-2 ps-0 text-end">
                                            <h4>x { tickets.length }</h4>
                                        </div>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item  d-flex justify-content-between align-items-center">
                                            Chosen Answer:
                                            <span className="badge">{ el.userAnswer }</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            Ticket No(s):
                                            <strong className="badge text-danger" id="ticket-position">{ tickets }</strong>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            Price:
                                            <span className="badge"><i className="fa fa-euro"></i> { el.competitionAmount }</span>
                                        </li>
                                        <li className="list-group-item list-RowTotal d-flex justify-content-between align-items-center">
                                            Total Price:
                                            <strong className="badge text-dark"><i className="fa fa-euro"></i> { el.competitionAmount * tickets.length }</strong>
                                        </li>
                                        <li className="list-group-item list-WinnerNo d-flex justify-content-between align-items-center">
                                            <a href="#">Winner No(s):</a>
                                            <strong className="badge text-dark"> <a href="#">{ winnerTickets }</a></strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
            });
        }

        function createFormatAMPM(date) {
            if(!date) {
                return '';
            }
            else {
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var seconds = date.getSeconds();
                var ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                hours = hours < 10 ? '0'+hours : hours;
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return strTime;
            }
        }

        let date = '';
        if(OrderDetails.createdAt) {
            let time = createFormatAMPM(new Date(OrderDetails.createdAt));
            date = moment(OrderDetails.createdAt).format('DD/MM/YYYY') + ' ' + time;
        }

        return (
            <>
                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="title">
                                        <h2>Buy Tickets</h2>
                                        <div className="bdr-btm-heading">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="order-detail">
                            <div className="title text-center">
                                <h4>Order #{ OrderDetails.orderId }</h4>
                            </div>
                            <p>Your order was placed on <strong>{ date }</strong> and is currently { statusDetails }</p>
                            <div className="order-accordion">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Order Items
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="order-item">
                                                    <h6>{ numberOfItems } Items</h6>
                                                    
                                                    { orderItemsCards }
                                                    
                                                    <div className="row">
                                                        <div className="col-12 col-sm-8  offset-sm-4 col-md-8 offset-md-4 col-lg-9 offset-lg-3">
                                                            <div className="summary">
                                                                <div className="Price-Details mb-0">
                                                                    <ul className="list-group mb-0">
                                                                        <li className="list-group-item d-flex  justify-content-between align-items-center">
                                                                            <strong> Payment Method</strong>
                                                                            <strong className="badge">{ OrderDetails.paymentFrom }</strong>
                                                                        </li>
                                                                        <li className="list-group-item d-flex  justify-content-between align-items-center">
                                                                            Subtotal
                                                                            <span className="badge"><i className="fa fa-euro"></i> { OrderDetails.amount }</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center text-danger">
                                                                            Via Credit balance
                                                                            <span className="badge  text-danger"><i className="fa fa-euro"></i> { OrderDetails.amount } </span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            <strong>Grand Total</strong>
                                                                            <strong className="badge"><i className="fa fa-euro"></i> { OrderDetails.amount }</strong>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Billing Address
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <div className="Billing-item">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6 col-md-6">
                                                            <p><img src="/images/Profile-icon.png" alt="" />{ name }</p>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-6">
                                                            <p><img src="/images/loaction-icon0.png" alt="" />{ address }</p>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-6">
                                                            <p><img src="/images/telephone.png" alt="" />{ mobile }</p>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-6">
                                                            <p><img src="/images/email.png" alt="" />{ email }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}