import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';


export default class Wallet extends Component {

    state = {
        DataList: [],
        UserDetails: {},
        amount: '',
        pageNo: 1,
        length: 0,
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
      this.setState({ [e.target.id]: e.target.value });
    }

    createOrder = async () => {
      let token = localStorage.getItem('loginDetails');
      let metaData = { 'amount': this.state.amount };
      
      var { data } = await Axios.post(Constant.apiBasePath + 'user/generateOrderForWalletRecharge', metaData, { headers: { 'token': token }});
      if(data.status === Constant.statusSuccess) {
            metaData = {
                'orderId': data.data.orderId,
                'paymentId': data.data.orderId,
                'signature': data.data.orderId,
                "secret": data.data.orderId
            }
            var { data } = await Axios.post(Constant.apiBasePath + 'user/verifySignatureForWalletRecharge', metaData, { headers: { 'token': token }});
            if(data.status === Constant.statusSuccess) {
                this.setState({ "successMessage": data.message });
                setTimeout(() => { window.location.reload(); }, 2000);
            }
            else {
                this.setState({ "errorMessage": data.message });
                setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 2000);
                return false;
            }
      }
      else {
          this.setState({ "errorMessage": data.message });
          setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 2000);
          return false;
      }
    }

    // get wallet list

    getWalletList = async (pageNo) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user/getWalletHistories?pageNo=' + pageNo, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'DataList': data.data, 'pageNo': data.pageNo, 'length': data.length });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    getProfileDetails = async () => {
      let token = localStorage.getItem('loginDetails');
      let { data } = await Axios.get(Constant.apiBasePath + 'user/getProfileDetails', { headers: { 'token': token }});
      if(data.status === Constant.statusSuccess) {
          this.setState({ 'UserDetails': data.data });
      }
      else {
          this.setState({ 'errorMessage': data.message });
          return false;
      }
  }

    componentDidMount() {
        this.getWalletList(this.state.pageNo);
        this.getProfileDetails();
    }


    render() {
        let { DataList, pageNo, length, UserDetails } = this.state;
        let walletList = [];
        let wallet = 0;
        if(UserDetails) {
            wallet = UserDetails.wallet;
        }

        if(DataList.length > 0) {
            walletList = DataList.map(el => {
              let date = new Date(el.createdAt).toString();
              date = date.split(" ");
              date = date[2] + " " + date[1] + " " + date[3];

          return    <div className="table_row">
                      <div className="table_small">
                          <div className="table_cell">ID</div>
                          <div className="table_cell">{ el._id }</div>
                      </div>
                      <div className="table_small">
                          <div className="table_cell">Transection For </div>
                          <div className="table_cell">{ el.paymentFor }</div>
                      </div>

                      <div className="table_small">
                          <div className="table_cell">Date</div>
                          <div className="table_cell">{ date }</div>
                      </div>
                      <div className="table_small">
                          <div className="table_cell">Value</div>
                          {
                            (el.paymentType === 'Credit')
                            ?
                               <div className="table_cell"><div className="successGreenBorder"><i class="fa fa-plus me-1"></i>€{ el.amount }</div></div>
                            :
                               <div className="table_cell"><div className="successGreenBorder text-danger"><i class="fa fa-minus me-1"></i>€{ el.amount }</div></div>
                          }
                      </div>
                    </div>
            });
        }

        function goBack() {
            window.history.back();
        }


        return(
            <>
               <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><a href="index.php">Home</a></li>
                                    <li className="breadcrumb-item active">My Wallet</li>
                                    <li className="breadcrumb-item"><a onClick={ goBack }>Back</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap user-dashboard">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="account_dashboard my_orders_dashboard">
                                    <div className="page-header border-0 pb-0">
                                    <div className="d-inline-block">
                                        <h2>My Wallet</h2>
                                        <div className="bdr-btm-heading">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wallet-header">
                                    <div>
                                        <h3><img src="images/wallet.png"/> €{ wallet }</h3>
                                        <h5 className="mb-0">Available  Amount</h5>
                                    </div>
                                    <a href="#walletModal" data-toggle="modal" className="btn btn-dark"><i className="fa fa-plus"></i> Top Up My RO Wallet</a>
                                </div>
                                <hr/>
                                <div className="title-small d-flex justify-content-between align-items-center mt-4">
                                    <h3>Balance added History</h3> 
                                    <div className="form-group searchgroup mb-0">
                                        <input type="text" className="form-control form-ctrl" placeholder="Search..."/>
                                        <button type="submit" className="btn btn-link"><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                                <div className="login-white-box">
                                    <div className="orders_table_main">
                                        <div className="table-responsive">
                                            <div className="table">
                                                <div className="theader">
                                                    <div className="table_header">Order Id</div>
                                                    <div className="table_header">Transaction Id</div>
                                                    <div className="table_header">Payment Date</div>
                                                    <div className="table_header">Amount</div>
                                                </div>

                                                { walletList }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>     
                          </div>
                        </div>
                    </div>
                </section>

                {/* modal start */}

                <div className="modal fade" id="walletModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content feedback-popup">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="exampleModalLabel">Top Up My RO Wallet </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-box p-4">
                          <div className="form-group">
                              <label>Enter Amount</label>
                              <input type="text" id="amount" className="form-control form-ctrl" placeholder='Enter Amount' onChange={ this.handleChange }/>
                          </div>
                          {
                            (this.state.successMessage)
                            ?
                            <p className='text-success'>{ this.state.successMessage }</p>
                            : 
                            ""
                          }

                          {
                              (this.state.errorMessage)
                              ?
                              <p className='text-success'>{ this.state.errorMessage }</p>
                              : 
                              ""
                          }
                          <button type="submit" className="btn btn-warning btn-block btn-sumbit" onClick={ this.createOrder }>Save</button>
                        </div>
                    </div>
                  </div>
                </div>
                </div>

                {/* <!-- modal end --> */}

                <div className="modal fade" id="ratingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog">
                    <div className="modal-content feedback-popup">
                      <div className="modal-header justify-content-center">
                        <h5 className="modal-title text-center" id="exampleModalLabel">Rating &amp; Review </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                      <div className="form-box-login">
                          <div className="rating-stars">
                                                                  <i className="fa fa-star checked"></i>
                                                                  <i className="fa fa-star checked"></i>
                                                                  <i className="fa fa-star checked"></i>
                                                                  <i className="fa fa-star checked"></i>
                                                                  <i className="fa fa-star"></i>
                                                                  </div>
                      
                          <div className="form-box">
                          <div className="form-group">
                              <label>Your feedback</label>
                            <textarea className="form-control form-ctrl" rows="6"></textarea>
                            </div>
                            <button type="submit" className="btn btn-dark btn-block">Submit</button>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
      
            </>
        )
    }
}