import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';

export default class Home extends Component {

    state = {
        ContactDetails: {},
        name: "",
        mobile: "",
        email: "",
        cityName: "",
        message: "",
        homePageData: [],
        popularCompetition: [],
        LastChanceList: [],
        popularCount: 0,
        lastCount: 0,
        winnerList: [],
        competitionBannerList: [],
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    pageRedirect = (competitionId) => {
        this.props.history.push('/product-details/' + competitionId);
    }

    // send user query

    sendUserQuery = async () => {
        let metaData = {
            name: this.state.name,
            mobile: this.state.mobile,
            email: this.state.email,
            cityName: this.state.cityName,
            message: this.state.message
        }
        let { data } = await Axios.post(Constant.apiBasePath + 'user-enquiry', metaData);
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(()=> { window.location.reload() }, 3000);
        }
        else {
            this.setState({ "errorMessage": data.message });
            setTimeout(()=> { this.setState({ 'errorMessage': "" }) }, 3000);
            return false;
        }
    }

    // get contact details

    getContactDetails = async () => {
        let { data } = await Axios.get(Constant.apiBasePath + 'user/contact-us');
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'ContactDetails': data.data });
        }
        else {
            // this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    // get home page data

    getHomePageData = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.post(Constant.apiBasePath + 'homePageData', {}, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {    
            this.setState({ 'homePageData': data.data.docs, 'popularCompetition': data.data.popularCompetition, 'LastChanceList': data.data.lastChance, 
            'popularCount': data.data.popularCount, 'lastCount': data.data.lastCount, 'winnerList': data.data.winnerList, 'competitionBannerList': data.data.competitionBanner });
        }
        else {
            this.setState({ 'errorMessage': data.message });
        }
    }


    componentDidMount() {
        this.getContactDetails();
        this.getHomePageData();
    }

    pageRedirect(id) {
        console.log(id);
        window.location.href = `/product-details/${id}`;
    }

    render() {
        const { ContactDetails, homePageData, popularCompetition, LastChanceList, popularCount, lastCount, winnerList, competitionBannerList } = this.state; 
        let text = '';
        let mobile= '';
        let email = '';
        let address = '';
        let countryCode = '+91';

        if(ContactDetails.address !== undefined) {
            text = ContactDetails.text;
            mobile = ContactDetails.mobile;
            email = ContactDetails.email;
            address = ContactDetails.address;
            countryCode = ContactDetails.countryCode;
        }

        let homeBody = '';
        if(homePageData.length > 0) {
            homeBody = homePageData.map(el => {
                return <>
                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>{ el.name }</h2>
                                        <div className="bdr-btm-heading">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                    { 
                                        (el.count > 4) 
                                        ? 
                                            <Link to={`/competitions/${ el._id }`} className="btn btn-outline-dark">View All <i className="fa fa-angle-right"></i></Link>
                                        : 
                                            "" 
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                (el.competitionData.length > 0)
                                ?
                                    el.competitionData.map(item => {
                                        let date = '';
                                        if(item.endDate) {
                                            date = new Date(item.endDate).toString();
                                            date = date.split(" ");
                                            date = date[2] + " " + date[1] + " " + date[3];
                                        }

                                        return <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                            <div className="item-box">
                                                <div className="thumbnail tmb">
                                                    <img src={ item.images[0] } alt=""/>
                                                </div>
                                                <div className="item-box-dec">
                                                    {
                                                        (date)
                                                        ?
                                                        <p className="time">End { date } At { item.endTimeAMPM }</p>
                                                        :
                                                        null
                                                    }
                                                    <h5>{ item.name } </h5>
                                                    {
                                                        (item.progressBar)
                                                        ?
                                                        <div class="progress-bar-item">
                                                            <p>{ item.tickets.length } Ticket Sold</p>
                                                            <div class="progress">
                                                                <div class="progress-bar bg-warning" role="progressbar" style={{ width: item.tickets.length + "%" }} aria-valuenow={ item.tickets.length } aria-valuemin="0" aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div className="row align-items-center">
                                                        <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                            <p className="price mb-0"> <i className="fa fa-euro"></i> { item.perTicketPrice } <sub>/Ticket</sub></p>
                                                        </div>
                                                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                            <Link to={`/product-details/${item._id}`} className="btn btn-block btn-warning">{ item.competitionType } </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                :
                                null
                            }
                        </div>
                </>
            })
        }

        let popularCompetitionList = [];

        if(popularCompetition.length > 0) {
            popularCompetitionList = popularCompetition.map(el => {
                let date = '';
                if(el.endDate) {
                    date = new Date(el.endDate).toString();
                    date = date.split(" ");
                    date = date[2] + " " + date[1] + " " + date[3];
                }
                

                return  <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                            <div className="item-box">
                                <div className="thumbnail tmb">
                                    <img src={ el.images[0] }  alt=""/>
                                </div>
                                <div className="item-box-dec">
                                    {
                                        (date)
                                        ?
                                        <p className="time">End { date } At { el.endTimeAMPM }</p>
                                        :
                                        null
                                    }
                                    <h5>{ el.name }</h5>

                                    <div className="row align-items-center">
                                        <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                            <p className="price mb-0"> <i className="fa fa-euro"></i> { el.perTicketPrice } <sub>/Ticket</sub></p>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                            <Link to={`/product-details/${el._id}`} className="btn btn-block btn-warning">{ el.competitionType } </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            })
        }

        let LastChance = [];
        if(LastChanceList.length > 0) {
            LastChance = LastChanceList.map(el => {
                let date = '';
                if(el.endDate) {
                    date = new Date(el.endDate).toString();
                    date = date.split(" ");
                    date = date[2] + " " + date[1] + " " + date[3];
                }
                
                return <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                            <div className="item-box">
                                <div className="thumbnail tmb">
                                    <img src={ el.images[0] }  alt=""/>
                                </div>
                                <div className="item-box-dec">
                                    {
                                        (date)
                                        ?
                                        <p className="time">End { date } At { el.endTimeAMPM }</p>
                                        :
                                        null
                                    }
                                    <h5>{ el.name }</h5>

                                    <div className="row align-items-center">
                                        <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                            <p className="price mb-0"> <i className="fa fa-euro"></i> { el.perTicketPrice } <sub>/Ticket</sub></p>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                            <Link to={`/product-details/${el._id}`} className="btn btn-block btn-warning">{ el.competitionType } </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            });
        }

        let winners = [];
        if(winnerList.length > 0) {
            winners = winnerList.map(el => {
                let profilePic = (el.profilePic) ? el.profilePic : 'images/winners-img.png';
                let countryName = (el.countryName !== undefined) ? el.countryName : '';

                return  <li>
                            <div className="winners-item-box">
                                <div className="winners-tmb">
                                    <img src={ profilePic } alt=""/>
                                </div>
                                <div className="winners-item-dec">
                                    <h6>{ el.name } , { countryName }</h6>
                                    <h5>{ el.prizeName }</h5>
                                    <p className="price mb-0"> <i className="fa fa-euro"></i> { el.amount }</p>
                                </div>
                            </div>
                        </li>
            });
        }

        return (
            <>
            
                <section className="slider-wrap">
                    {
                        (competitionBannerList.length > 0) 
                        ?
                            <OwlCarousel items={1} className="owl-theme" loop nav dots={false} responsiveClass={true} margin={20}
                            responsive={{
                                0: {
                                    items: 1
                                },

                                768: {
                                    items: 1
                                },

                                1024: {
                                    items: 1
                                },

                                1366: {
                                    items: 1
                                }
                            }}
                        >
                            
                            { competitionBannerList.map(el => 
                               <div className='item'><img src={el.image} onClick={ ()=> { this.pageRedirect(el.competitionId) }}/></div>
                            )}
                            </OwlCarousel>
                        : 
                        null
                    }
                </section>
                <section className="wrap competitions-wrap">
                    <div className="container-fluid">
                        
                        { homeBody }

                    </div>
                </section>
                <section className="wrap competitions-wrap wrap-top0">
                    <div className="container-fluid">

                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>Last Chance</h2>
                                        <div className="bdr-btm-heading"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                    {
                                        (lastCount === 1)
                                        ?
                                           <Link to={`/competitions/2`} className="btn btn-outline-dark">View All <i className="fa fa-angle-right"></i></Link>
                                        :
                                           ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            { LastChance }

                        </div>

                    </div>
                </section>
                {/* <section className="wrap competitions-wrap wrap-top0">
                    <div className="container-fluid">

                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>Cash</h2>
                                        <div className="bdr-btm-heading"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                    <a className="btn btn-outline-dark" href="competitions.php">View All <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/cash-img1.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/cash-img1.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/cash-img1.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/cash-img1.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="wrap competitions-wrap wrap-top0">
                    <div className="container-fluid">

                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>Electronics </h2>
                                        <div className="bdr-btm-heading"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                    <a className="btn btn-outline-dark" href="#">View All <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/electronics-img.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/electronics-img0.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/electronics-img1.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/electronics-img2.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}
                <section className="wrap competitions-wrap wrap-top0">
                    <div className="container-fluid">

                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>Popular </h2>
                                        <div className="bdr-btm-heading"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                    {
                                        (popularCount > 4)
                                        ?
                                           <Link to={`/competitions/1`} className="btn btn-outline-dark">View All <i className="fa fa-angle-right"></i></Link>
                                        :
                                           ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                           
                           { popularCompetitionList }

                        </div>

                    </div>
                </section>
                {/* <section className="wrap competitions-wrap wrap-top0">
                    <div className="container-fluid">

                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>Holidays</h2>
                                        <div className="bdr-btm-heading"></div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                    <a className="btn btn-outline-dark" href="#">View All <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/holidays-img.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/holidays-img0.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/holidays-img1.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                <div className="item-box">
                                    <div className="thumbnail tmb">
                                        <img src="images/holidays-img2.png"  alt=""/>
                                    </div>
                                    <div className="item-box-dec">
                                        <p className="time">End 28 July 2023 At 12:00 AM</p>
                                        <h5>Volvo XC90 B5D R-Design Pro</h5>

                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                                <p className="price mb-0"> <i className="fa fa-euro"></i> 10.4 <sub>/Ticket</sub></p>
                                            </div>
                                            <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                                <a href="#" className="btn btn-block btn-warning">Enter Now </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}
                <section className="wrap bg-light winners-wrap">
                    <div className="container-fluid">

                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>Winners</h2>
                                        <div className="bdr-btm-heading"></div>
                                    </div>
                                </div>
                                {
                                    (winnerList.length > 4)
                                    ?
                                        <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                            <Link className="btn btn-outline-dark" to="/our-winners">View All <i className="fa fa-angle-right"></i></Link>
                                        </div>
                                    :
                                        null
                                }
                            </div>
                        </div>
                        <ul className="list-inline ul-five-box d-flex flex-wrap text-center">
                            { winners }
                        </ul>

                    </div>
                </section>
                <section className="howitwork-wrap">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                <div className="title">
                                    <h2><span className="small">How We</span> Work?</h2>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                                <div className="d-flex align-items-center justify-content-between howitwork-step-withfaq">
                                    <ul className="list-inline d-flex justify-content-between align-items-center howitwork-step mb-0">
                                        <li><a href="#"><span>1</span> Choose</a></li>
                                        <li><a href="#"><span>2</span> Select</a></li>
                                        <li><a href="#"><span>3</span> Pay</a></li>
                                        <li><a href="#"><span>4</span> Tune In</a></li>
                                    </ul>
                                    <div className="faq-view-btn">
                                        <Link to="/faqs">View FAQ</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                <section className="wrap testimonials-wrap">
                    <div className="container-fluid">
                        <div className="slide-heading">

                            <div className="title">
                                <h2>Testimonial</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                                        <p>The Marketing Accountability Standards Board (MASB) endorses the definitions, purposes, and constructs of classNamees</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="tastimonial">
                            <OwlCarousel items={3} className="owl-theme" loop nav dots={false} responsiveClass={true} margin={20}
                                responsive={{
                                    0: {
                                        items: 1
                                    },

                                    768: {
                                        items: 2
                                    },

                                    1024: {
                                        items: 3
                                    },

                                    1366: {
                                        items: 3
                                    }
                                }}
                            >
                                    <div className="item">
                                        <div className="single-testimonial-item">
                                            <div className="user-img"><img src="images/client-img.png"  alt=""/></div>
                                            <p>Not weekly or monthly like other sites out there. This ensures that we offer prospective homebuyers and investors with the freshest- hottest deals on the Internet.</p>
                                            <div className="user-name">
                                                <h4>Jenny Wilson</h4>
                                                <h5>ID 98452121545789</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="single-testimonial-item">
                                            <div className="user-img"><img src="images/client-img0.png"  alt=""/></div>
                                            <p>Not weekly or monthly like other sites out there. This ensures that we offer prospective homebuyers and investors with the freshest- hottest deals on the Internet.</p>
                                            <div className="user-name">
                                                <h4>Brooklyn Simmons</h4>
                                                <h5>ID 98452121545789</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="single-testimonial-item">
                                            <div className="user-img"><img src="images/client-img1.png"  alt=""/></div>
                                            <p>Not weekly or monthly like other sites out there. This ensures that we offer prospective homebuyers and investors with the freshest- hottest deals on the Internet.</p>
                                            <div className="user-name">
                                                <h4>Bessie Cooper</h4>
                                                <h5>ID 98452121545789</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="single-testimonial-item">
                                            <div className="user-img"><img src="images/client-img0.png"  alt=""/></div>
                                            <p>Not weekly or monthly like other sites out there. This ensures that we offer prospective homebuyers and investors with the freshest- hottest deals on the Internet.</p>
                                            <div className="user-name">
                                                <h4>Brooklyn Simmons</h4>
                                                <h5>ID 98452121545789</h5>
                                            </div>
                                        </div>
                                    </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
                {/* <section className="wrap bg-light">
                    <div className="container-fluid">

                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                    <div className="title">
                                        <h2>Frequently asked questions</h2>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                    <a className="btn btn-outline-dark" href="#">View All <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="faq-dec">
                            <h4>Forget the old rules. You can have the best people. Right now You can have the best peopleYou  the best people</h4>
                            <p>Not weekly or monthly like other sites out there. This ensures that we offer prospective homebuyers and investors with the freshest- hottest deals on the Internet. Forget the old rules. You can have the best people. Right now You can have the best people You  the best people Not weekly or monthly like other sites out there. This ensures that we offer prospective homebuyers and investors with the freshest- hottest deals on the Internet. Forget the old rules. You can have the best people. Right now You can have the best people You  the best people</p>
                        </div>
                    </div>
                </section> */}
                <section className="wrap">
                    <div className="container-fluid">
                        <div className="contact-sec">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-5">
                                    <div className="title">
                                        <h2>Contact Us</h2>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-10 col-lg-10  col-xl-10">
                                                <p>{ text }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media fotr-media">
                                        <div className="media-left">
                                            <div className="fotr-media-icon"><i className="fa fa-phone"></i></div>
                                        </div>
                                        <div className="media-body">
                                            <h6>Our Contact Number  </h6>
                                            <p><a href="tel:+011 6542021546">{ countryCode + ' ' + mobile }</a></p>
                                        </div>
                                    </div>
                                    <div className="media fotr-media">
                                        <div className="media-left">
                                            <div className="fotr-media-icon"><i className="fa fa-envelope"></i></div>
                                        </div>
                                        <div className="media-body">
                                            <h6>Email ID   </h6>
                                            <p><a href="mailto:rocompetition@gmail.com">{ email }</a></p>
                                        </div>
                                    </div>
                                    <div className="media fotr-media">
                                        <div className="media-left">
                                            <div className="fotr-media-icon"><i className="fa fa-map-marker"></i></div>
                                        </div>
                                        <div className="media-body">
                                            <h6>Office Address   </h6>
                                            <p>{ address }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-7">
                                    <div className="contact-form">
                                        <div className="title">
                                            <h3>Leave query with us</h3>
                                            {
                                                (this.state.successMessage)
                                                ?
                                                <p className='text-success'>{ this.state.successMessage }</p>
                                                :
                                                (this.state.errorMessage)
                                                ?
                                                <p className='text-danger'>{ this.state.errorMessage }</p>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Full Name</label>
                                                    <input type="text" id="name" className="form-control form-ctrl" placeholder="Type here" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Number</label>
                                                    <input type="number" id="mobile" className="form-control form-ctrl" placeholder="Type here" onChange = { this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="email" id="email" className="form-control form-ctrl" placeholder="Type here" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>City Name</label>
                                                    <input type="text" id="cityName" className="form-control form-ctrl" placeholder="Type here" onChange={ this.handleChange }/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Enter Message</label>
                                            <textarea rows="6" id="message" className="form-control form-ctrl" placeholder="Type here" onChange={ this.handleChange }></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-warning btn-block" onClick={ this.sendUserQuery }>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
