import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import $ from 'jquery';


export default class OurWinners extends Component {

    state = {
        DataList: [],
        pageNo: 1,
        length: 0,
        successMessage: '',
        errorMessage: ''
    }

    // our winners

    getList = async (event) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'getWinnerList?pageNo=' + event, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'DataList': data.data, 'pageNo': data.pageNo, 'length': data.length });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getList(this.state.pageNo);
    }


    render() {
        let { DataList, pageNo, length } = this.state;
      
        let winners = [];

        if(DataList.length > 0) {
            winners = DataList.map(el => {
                let profilePic = (el.profilePic) ? el.profilePic : 'images/winners-img.png';
                let countryName = (el.countryName !== undefined) ? el.countryName : '';
                
                return <div class="col-6 col-sm-6 col-md-6 col-lg-4">
                            <div class="winners-item-box">
                                <div class="winners-tmb">
                                    <img src={ profilePic } alt=""/>
                                </div>
                                <div class="winners-item-dec">
                                    <h6>{ el.name }  , { countryName }</h6>
                                    <h4>{ el.prizeName }</h4>
                                    <p class="price mb-0"> <i class="fa fa-euro"></i>{ el.amount }</p>
                                </div>
                            </div>
                        </div>
            });
        }
    
        return(
            <section class="wrap winners-wrap">
                <div class="container-fluid">
                    <div class="slide-heading">
                        <div class="title">
                            <h2>Our Winners</h2>
                            <div class="bdr-btm-heading">
                                
                            </div>
                            {
                                (this.state.errorMessage)
                                ?
                                    <p className='text-danger'>{ this.state.errorMessage }</p>

                                :
                                    null
                            }
                        </div>
                    </div>
                    <div class="row winners-listing text-center">
                        { winners }
                    </div>
                </div>
                <div class="slide-heading">
                    <div class="row text-center">
                        <div class="col-md-12 col-sm-12 col-12">
                            {
                                (length > 1)
                                ?
                                <div class="view-btn">
                                    <a class="btn btn-outline-dark" href="#" onClick={ ()=> { this.getList(pageNo + 1) }}>View All <i class="fa fa-angle-down"></i></a>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}