import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import $ from 'jquery';


export default class Checkout extends Component {

    state = {
        CartList: [],
        couponCodeId: '',
        couponCode: '',
        percentOfDiscount: 0,
        CompetitionDetails: {},
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }


    // get my carts

    getMyCart = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'order/my-cart', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CartList': data.data.carts });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    // get competition details

    getCompetitonDetails = async (competitionId) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'competition/details/' + competitionId, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CompetitionDetails': data.data });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    // verify coupon code

    verifyCoupon = async () => {
        let token = localStorage.getItem('loginDetails');
        let competitionId = (this.props.match.params.id) ? [this.props.match.params.id] : [];
        let type = window.atob(this.props.match.params.type);
        let CartList = this.state.CartList;

        if(parseInt(type) === 2 && CartList.length > 0) {
            let competitionIds = CartList.map(el => { return el.competitionId });
            competitionId = competitionIds
        }
        let couponCode = this.state.couponCode;
        let metaData = {
            'competitionId': competitionId,
            'couponCode': couponCode
        }

        let { data } = await Axios.post(Constant.apiBasePath + 'coupon/verifyCouponCode', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message, "errorMessage": "", 'percentOfDiscount': data.data.percentOfDiscount, 'couponCodeId': data.data._id });
        }
        else {
            this.setState({ "errorMessage": data.message, 'successMessage': "" });
        }
    }

    componentDidMount() {
        let competitionId = this.props.match.params.id;
        let type = window.atob(this.props.match.params.type);
        if(parseInt(type) === 2)
        {
            this.getMyCart();
        }
        else {
            this.getCompetitonDetails(competitionId);
        }
    }


    render() {
        let { CartList, CompetitionDetails, percentOfDiscount, couponCodeId } = this.state;
        let cartItems = [];
        let priceOfTicket = 0;
        let quantity = window.atob(this.props.match.params.quantity);
        let discountAmount = 0;
        let payPrice = 0;

        if(CartList.length > 0) { 
            cartItems = CartList.map(el => {
                quantity = 0;
                priceOfTicket = el.competitionData.perTicketPrice * el.quantity;
                quantity += el.quantity;

                return <div className="cart-item alert alert-dismissible fade show" role="alert">
                            <div className="media">
                                <div className="media-left">
                                    <div className="product-img">
                                        <img src={ el.competitionData.images[0] } alt=""/>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <div className="d-flex justify-content-between">
                                        <h5><Link to="/product-detail">{ el.competitionData.name }</Link></h5>
                                        <h4 className="price"> <span><i className="fa fa-euro"></i>{ el.competitionData.perTicketPrice }<sub>/Ticket</sub></span> </h4>
                                    </div>
                                    <h4 className="price"> <span><i className="fa fa-euro"></i> { el.competitionData.perTicketPrice } <sub>/Ticket</sub></span> </h4>
                                    <div className="row">
                                        <div className="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-8">
                                            <p className="mb-1">Answer selected</p>
                                            <h5>Standards Board</h5>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 text-end">
                                            <ul className="list-inline d-flex align-items-end justify-content-end">
                                                {/* <li><div className="wishlist-delete"><button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"><i className="fa fa-trash-o"></i> </button></div></li> */}
                                                <li>
                                                <div className="qty-btn">
                                                <h6>Ticket Quantity { el.quantity }</h6>
                                                {/* <div id="field1">
                                                    <button type="button" id="sub" className="sub">-</button>
                                                    <input className="qty-input" type="number" id="1" value={ el.quantity } min="1" max="10"/>
                                                    <button type="button" id="add" className="add">+</button>
                                                </div> */}
                                            </div>
                                        </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            });
            priceOfTicket = priceOfTicket.toFixed(2);
        }

        if(CompetitionDetails.name !== undefined) {
            priceOfTicket = CompetitionDetails.perTicketPrice * quantity;
            priceOfTicket = priceOfTicket.toFixed(2);

            cartItems = <div className="cart-item alert alert-dismissible fade show" role="alert">
                            <div className="media">
                                <div className="media-left">
                                    <div className="product-img">
                                        <img src={ CompetitionDetails.images[0] } alt=""/>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <div className="d-flex justify-content-between">
                                        <h5><Link to="/product-detail">{ CompetitionDetails.name }</Link></h5>
                                        <h4 className="price"> <span><i className="fa fa-euro"></i>{ CompetitionDetails.perTicketPrice }<sub>/Ticket</sub></span> </h4>
                                    </div>
                                    <h4 className="price"> <span><i className="fa fa-euro"></i> { CompetitionDetails.perTicketPrice } <sub>/Ticket</sub></span> </h4>
                                    <div className="row">
                                        <div className="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-8">
                                            <p className="mb-1">Answer selected</p>
                                            <h5>Standards Board</h5>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 text-end">
                                            <ul className="list-inline d-flex align-items-end justify-content-end">
                                                {/* <li><div className="wishlist-delete"><button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"><i className="fa fa-trash-o"></i> </button></div></li> */}
                                                <li>
                                                    <div className="qty-btn">
                                                        <h6>Ticket Quantity { quantity }</h6>
                                                        {/* <div id="field1">
                                                            <button type="button" id="sub" className="sub">-</button>
                                                            <input className="qty-input" type="number" id="1" value={ quantity } min="1" max="10"/>
                                                            <button type="button" id="add" className="add">+</button>
                                                        </div> */}
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        }

        let id = this.props.match.params.id;
        let type = window.atob(this.props.match.params.type);

        payPrice = priceOfTicket;
        if(percentOfDiscount > 0) {
            discountAmount = (priceOfTicket * percentOfDiscount) / 100;
            payPrice = priceOfTicket - discountAmount;
        }

    
        // encrypt area

        let encryptPayPrice = window.btoa(payPrice);
        let encryptQuantity = window.btoa(quantity);
        let encryptType = window.btoa(type);


        return (
            <>
                <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Order Review</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="page-header">
                            <h3>Order Review <span className="badge">{ (type === 1) ? 1 : CartList.length } </span></h3>
                            <div className="bdr-btm-heading">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8"> 
                                <form className="form-cart">
                                    
                                    { cartItems }

                                </form>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4 productIncart">
                                <div className="summary">
                                    <h3>Order Summary</h3>
                                    {
                                        (this.state.successMessage)
                                        ?
                                           <p className='text-success'>{ this.state.successMessage }</p>
                                        :
                                        null
                                    }
                                    {
                                        (this.state.errorMessage)
                                        ?
                                           <p className='text-danger'>{ this.state.errorMessage }</p>
                                        :
                                        null
                                    }
                                    <div className="form-coupan">
                                        <div className="form-group">
                                        <label>Apply Code</label>
                                        <input type="text" id="couponCode" className="form-control form-ctrl" placeholder="Enter" onChange={ this.handleChange }/>
                                            <button type="submit" className="btn btn-link" onClick={ this.verifyCoupon }>Apply</button>
                                        </div>
                                        {/* <h6><a href="#">Apply Code</a></h6> */}
                                    </div>
                                    <div className="Price-Details">
                                        <ul className="list-group">
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                Amount ({ quantity } items).
                                                <span className="badge"><i className="fa fa-euro"></i> { priceOfTicket }</span>
                                            </li>
                                            {
                                                (discountAmount)
                                                ?
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    Amount of Discount
                                                    <span className="badge"><i class="fa fa-minus me-1"></i><i className="fa fa-euro"></i> { discountAmount }</span>
                                                </li>
                                                :
                                                null
                                            }
                                        </ul>
                                        <ul className="list-group totalpay">
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                You Pay 
                                                <span className="badge"><i className="fa fa-euro"></i> { payPrice }</span>
                                            </li>
                                        </ul>
                                    </div>
                                    {
                                        (couponCodeId)
                                        ?
                                        <Link to={`/billing-info/${id}/${encryptPayPrice}/${encryptQuantity}/${encryptType}/${couponCodeId}`} className="btn btn-warning btn-block btn-lg">Proceed to Checkout</Link>
                                        :
                                        <Link to={`/billing-info/${id}/${encryptPayPrice}/${encryptQuantity}/${encryptType}`} className="btn btn-warning btn-block btn-lg">Proceed to Checkout</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
    