import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';

export default class Competition extends Component {

    state = {
        DataList: [],
        pageNo: 1,
        length: 0,
        categoryName: '',
        successMessage: '',
        errorMessage: ''
    }

    // get home page data

    getHomePageData = async (pageNo) => {
        let token = localStorage.getItem('loginDetails');
        let categoryId = this.props.match.params.id;
        let metaData = { 'categoryId': categoryId, 'pageNo': pageNo };
        let { data } = await Axios.post(Constant.apiBasePath + 'competition/getDataByCategoryId', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'DataList': data.data, 'pageNo': data.pageNo, 'length': data.length, 'categoryName': data.getCategoryDetails.name });
        }
        else {
            this.setState({ 'errorMessage': data.message });
        }
    }

    componentDidMount() {
        this.getHomePageData(this.state.pageNo);
    }

    render() {
        const { DataList } = this.state;
        let { categoryName } = this.state
        let id = this.props.match.params.id;

        let bodyData = [];

        if(DataList.length > 0) {
            bodyData = DataList.map(el => {
                let date = '';
                if(el.endDate) {
                    date = new Date(el.endDate).toString();
                    date = date.split(" ");
                    date = date[2] + " " + date[1] + " " + date[3];
                }
                return <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="item-box">
                                <div className="thumbnail tmb">
                                    <img src={ el.images[0] } alt=''/>
                                </div>
                                <div className="item-box-dec">
                                    {
                                        (date)
                                        ?
                                        <p className="time">End { date } At { el.endTimeAMPM }</p>
                                        :
                                        null
                                    }
                                    <div className="row align-items-center">
                                        <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                            <p className="price mb-0"> <i className="fa fa-euro"></i> { el.perTicketPrice} <sub>/Ticket</sub></p>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                            <Link to={`/product-details/${el._id}`} className="btn btn-block btn-warning">{ el.competitionType}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            })
        }

        if(!categoryName) {
            id = parseInt(id);
            if(id === 1) {
                categoryName = 'Popular';
            }
            else if(id === 2) {
                categoryName = 'Last Chance';
            }
        }

        return (
            <section className="wrap competitions-wrap">
                <div className="container-fluid">
                    <div className="slide-heading">
                        <div className="row">
                            <div className="col-md-8 col-sm-8 col-8">
                                <div className="title">
                                    <h2>{ categoryName }</h2>
                                    <div className="bdr-btm-heading">
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4 col-sm-4 col-4 text-end view-btn">
                                <a className="btn btn-outline-dark" href="#">View All <i className="fa fa-angle-right"></i></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="row competitions-listing">
                        { bodyData }
                    </div>
                </div>
            </section>
        )
    }
}
    