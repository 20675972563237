import React, { Component } from 'react';

import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';


export default class ForgotPassword extends Component {

    state = {
        email: '',
        emailError: '',
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        if(this.state.email === '') {
            this.setState({ 'errorEmail': 'Email is required!' });
            return false;
        }
        else {
            this.setState({ 'errorEmail': '' });
        }

        let { data } = await Axios.post(Constant.apiBasePath + 'user/forgotPassword', { 'email': this.state.email });
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(() => { window.location.href = '/otp/' + data.data + '/2' }, 3000);
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }
    
    render() {
        return(
            <>
                <section className="wrap">
                    <div className="container">
                        <div className="backbtn">
                            <Link to="/login"><img src="images/arrow-a-left.png"/> Back to login</Link>
                        </div>
                        <div className="form-box form-box-login">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                                    <div className="login-white-box login-box-height">
                                        <div className="title text-center">
                                            <h3 className="mb-3">Forgot <span className="text-yellow">Password</span></h3>
                                            <p>Enter your registered Email ID</p>
                                            {
                                                (this.state.successMessage)
                                                ?
                                                <p className="text-success">{ this.state.successMessage }</p>
                                                :
                                                (this.state.errorMessage)
                                                ?
                                                <p className="text-danger"> { this.state.errorMessage }</p>
                                                :
                                                ""
                                            }
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <input type="email" id="email" onChange={ this.handleChange } className="form-ctrl form-control" placeholder="Email ID"/>
                                                <p className='text-danger'>{ this.state.errorEmail }</p>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-warning btn-gradent btn-block btn-sumbit" onClick={this.handleSubmit}>Get OTP</button>
                                            </div>
                                            <div className="or"><span>Or</span></div>
                                            
                                            <div className="signp-with-social">
                                                <a href="#"><img src="images/fb-icon.png"/> <span>Facebook</span></a>
                                                <a href="#"><img src="images/google-icon.png"/> <span>Google</span></a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}