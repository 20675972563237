import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';


export default class OTP extends Component {

    state = {
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        successMessage: '',
        errorMessage: '',
        counter: 45,
        token: "",
        errorOTP: ""
    }


    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    verifyOTP = async (e) => {
        e.preventDefault();
        let token = this.state.token;
        if(!token) {
            token = this.props.match.params.token;
        }

        let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;

        if(otp === '') {
            this.setState({ 'errorOTP': 'otp is required!' });
            return false;
        }
        else {
            this.setState({ 'errorOTP': '' });
        }

        let { data } = await Axios.post(Constant.apiBasePath + 'user/verifyOTP', { token, otp });
        let type = parseInt(this.props.match.params.type);
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            localStorage.setItem('loginDetails', data.data);
            if(type === 1) {
                // setTimeout(() => { window.location.href = '/login' }, 3000);
            // get user product cart details
            let addToCartDetails = localStorage.getItem('addToCartDetails');
            addToCartDetails = JSON.parse(addToCartDetails);

            // get user buy now product details
            let buyNowDetails = localStorage.getItem('buyNowDetails');
            buyNowDetails = JSON.parse(buyNowDetails);

          
            if(addToCartDetails) {
                let answer = localStorage.getItem('addToAnuwerCartDetails');
                answer = JSON.parse(answer)
                let metaData = {competitionId: answer.competitionId, userChooseOption: answer.option}
                await Axios.post(Constant.apiBasePath + 'competition/saveUserAnswer', metaData, { headers: { 'token': data.data }});
                
                let response = await Axios.post(Constant.apiBasePath + 'order/save-in-my-cart', addToCartDetails, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('addToCartDetails');
                    localStorage.getItem('addToAnuwerCartDetails');
                    setTimeout(() => { window.location.href = '/cart' }, 3000);
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${addToCartDetails.competitionId}` }, 3000);
                }
            }else if(buyNowDetails){
                let answer = localStorage.getItem('addToAnuwerCartDetails');
                answer = JSON.parse(answer)
                let metaData = {competitionId: answer.competitionId, userChooseOption: answer.option}
                await Axios.post(Constant.apiBasePath + 'competition/saveUserAnswer', metaData, { headers: { 'token': data.data }});
                
                let response = await Axios.post(Constant.apiBasePath + 'order/buy-now/' + buyNowDetails.id, {}, { headers: { 'token': data.data }});
                if(response.data.status === Constant.statusSuccess) {
                    localStorage.removeItem('buyNowDetails');
                    this.props.history.push(`/checkout/${buyNowDetails.id}/${buyNowDetails.encryptType}/${buyNowDetails.encryptQuantity}`);
                }
                else {
                    // this.setState({ 'errorMessage': data.message });
                    setTimeout(() => { window.location.href = `/product-details/${buyNowDetails.id}` }, 3000);
                }
            }
            else {
                setTimeout(() => { window.location.href = '/' }, 3000);
            }

            
            }
            else {
                setTimeout(() => { window.location.href = '/reset-password/' + data.data }, 3000);
            }
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    resendOTP = async () => {
        let token = this.props.match.params.token;
        let { data } = await Axios.post(Constant.apiBasePath + 'user/resendOTP', { token });
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message, 'token': data.data, counter: 45 });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        let interval = setInterval(() => { 
            if(this.state.counter > 0) {
                this.setState({ 'counter': (this.state.counter - 1) })
            }
            else {
                clearInterval(interval);
            } 
        }, 1000);
    }
    
    render() {

        /* OTP input fields validatation */

        var inputQuantity = [];
        $(function() {     
            $(".form-ctrl-otp").on("keyup", function (e) {
                var $field = $(this),
                    val=this.value,
                    $thisIndex=parseInt($field.data("idx"),10); 
                if (this.validity && this.validity.badInput || isNaN(val) || $field.is(":invalid") ) {
                    this.value = inputQuantity[$thisIndex];
                    return;
                } 
                if (val.length > Number($field.attr("maxLength"))) {
                    val=val.slice(0, 1);
                    $field.val(val);
                }
                inputQuantity[$thisIndex]=val;
            });      
        });
 
        $(document).ready(function(){
            $("#otp1").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp2").focus();
                }
                else {
                    $("#otp1").focus();
                }
            });

            $("#otp2").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp3").focus();
                }
                else {
                    $("#otp1").focus();
                }
            });

            $("#otp3").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp4").focus();
                }
                else {
                    $("#otp2").focus();
                }
            });

            $("#otp4").keyup(function(){
                if(this.value.length === parseInt(this.attributes["maxLength"].value)) {
                $("#otp5").focus();
                }
                else {
                    $("#otp3").focus();
                }
            });
        });
 
        /* End */ 


        return(
            <section className="wrap">
                <div className="container">
                    <div className="backbtn">
                        <Link to="/login"><img src="/images/arrow-a-left.png"/> Back to login</Link>
                    </div>
                    <div className="form-box form-box-login otp-box">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                                <div className="login-white-box login-box-height">
                                    <div className="title text-center">
                                        <h3 className="mb-3">Enter your <span className="text-yellow"> OTP</span></h3>
                                        <p>Enter 4 digit OTP code sent to your Email Id </p>
                                        {
                                            (this.state.successMessage)
                                            ?
                                            <p className="text-success">{ this.state.successMessage }</p>
                                            :
                                            (this.state.errorMessage)
                                            ?
                                            <p className="text-danger"> { this.state.errorMessage }</p>
                                            :
                                            ""
                                        }
                                    </div>
                                    <form>
                                        <div className="form-group">
                                            <input type="number" id="otp1" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                            <input type="number" id="otp2" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                            <input type="number" id="otp3" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                            <input type="number" id="otp4" className="form-ctrl form-control form-ctrl-otp" placeholder="*" onChange={this.handleChange} min="0" max="9" maxLength="1"/>
                                        </div>
                                        <p className='text-danger text-center'>{ this.state.errorOTP }</p>
                        
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-warning btn-gradent btn-block btn-sumbit" onClick={this.verifyOTP}>Verify </button>
                                        </div>
                                        <div className="form-group resend-otp text-center">
                                            <p> <span id="timer">{ this.state.counter }</span></p>
                                        </div>
                                        <div className="form-group forgot-txt SignUp-txt text-center">
                                            <p>Didn't receive OTP?  <button type="button" className="btn btn-success" disabled={ this.state.counter === 0 ? "" : "disabled" } onClick={()=> this.resendOTP() }> Resend</button></p>
                                        </div>
                                    </form>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}