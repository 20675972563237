import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';


export default class Draws extends Component {

    state = {
        DataList: [],
        pageNo: 1,
        length: 0,
        successMessage: '',
        errorMessage: ''
    }

    // get draws list

    getDrawsList = async (pageNo) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'draws/getForWebApp?pageNo=' + pageNo, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'DataList': data.data, 'pageNo': data.pageNo, 'length': data.length });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getDrawsList(this.state.pageNo);
    }


    render() {
        let { DataList, pageNo, length } = this.state;
        let drawsList = [];

        if(DataList.length > 0) {
            drawsList = DataList.map(el => {
                return <div class="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div class="draws-video">
                                <div class="achievement-video">
                                    <iframe class="embed-responsive-item" src={ el.videoURL } allow="fullscreen;"></iframe>
                                </div>
                                <h4>{ el.videoDate }</h4>
                            </div>
                        </div>
            });
        }
        return(

            <section class="wrap winners-wrap">
                <div class="container-fluid">
                    <div class="slide-heading">
                        <div class="title">
                            <h2>Draws</h2>
                            <div class="bdr-btm-heading">
                            </div>
                        </div>
                    </div>
                    <div class="row draws-listing text-center">
                        
                        { drawsList }

                    </div>
                    <div class="slide-heading">
                        <div class="row text-center">
                            <div class="col-md-12 col-sm-12 col-12">
                                {
                                    (pageNo < length)
                                    ?
                                    <div class="view-btn">
                                        <button type="button" class="btn btn-outline-dark" onClick={()=> { this.getDrawsList(pageNo)}}>View All <i class="fa fa-angle-down"></i></button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
