import React, { Component } from 'react';

export default class ThankYou extends Component {
    
    render() {
        return(
            <section class="wrap">
            <div class="container">
                <div class="inner-thanks text-center">
                    <div class="thankyou-img">
                    <img src="images/thanks-img.png"/>
                    </div>
                <h3>Thank you</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum</p>
                    <a href="index.php" class="btn btn-dark">Back to home</a>
                </div>
            </div>
       </section>
        )
    }
}