import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';


export default class ResetPassword extends Component {

    state = {
        password: '',
        confirmPassword: '',
        passwordError: '',
        confirmPasswordError: '',
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        let token = this.props.match.params.token;

        // validation
        this.inputFieldValidation();
        if(password === '' || confirmPassword === '') {
            return false;
        }

        let metaData = {
            'password': password,
            'confirmPassword': confirmPassword
        }
        let { data } = await Axios.patch(Constant.apiBasePath + 'user/setUserPassword', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(() => { window.location.href = '/login' }, 3000);
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    inputFieldValidation = () => {
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        if(password === '') {
            this.setState({ 'errorPassword': 'password is required!' });
        }
        else {
            this.setState({ 'errorPassword': '' });
        }
       
        if(confirmPassword === '') {
            this.setState({ 'confirmPasswordError': 'Confirm password is required!' });
        }
        else {
            this.setState({ 'confirmPasswordError': '' });
        }
    }
    
    render() {
        return(
            <section className="wrap">
                <div className="container">
                    <div className="backbtn">
                        <Link to="/login"><img src="/images/arrow-a-left.png"/> Back to login</Link>
                    </div>
                    <div className="form-box form-box-login">
                        <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                            <div className="login-white-box login-box-height">
                                <div className="title text-center">
                                    <h3 className="mb-3">Let’s Create your new <span className="text-yellow">password</span></h3>
                                    <p>Please choose strong password</p>
                                    {
                                        (this.state.successMessage)
                                        ?
                                        <p className="text-success">{ this.state.successMessage }</p>
                                        :
                                        (this.state.errorMessage)
                                        ?
                                        <p className="text-danger"> { this.state.errorMessage }</p>
                                        :
                                        ""
                                    }
                                </div>
                                <form>
                                    <div className="form-group">
                                    <input type="password" id="password" onChange={this.handleChange} className="form-ctrl form-control" placeholder="New password"/>
                                    <p className='text-danger'>{ this.state.errorPassword }</p>
                                </div>
                    
                                <div className="form-group">
                                    <input type="password" id="confirmPassword" onChange={this.handleChange} className="form-control form-ctrl" placeholder="Confirm password"/>
                                    <p className='text-danger'>{ this.state.confirmPasswordError }</p>  
                                    <div className="form_icon">
                                    <span toggle="#confirmPassword" className="fa fa-fw field-icon toggle-password fa-eye"></span></div> 
                                </div>
                       
                                <button type="submit" className="btn btn-warning btn-gradent btn-block btn-sumbit" onClick={this.handleSubmit}>Create Now</button>
                                </form>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}