import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import $ from 'jquery';


export default class Card extends Component {

    state = {
        CartList: [],
        successMessage: '',
        errorMessage: '',
        items: 0
    }

    // get my carts

    getMyCart = async (id) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'order/my-cart', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CartList': data.data.carts, items: data.data.carts.length });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    deleteCart = async (id) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.delete(Constant.apiBasePath + 'order/my-cart/' + id, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(() => { window.location.href = '/cart' }, 3000);
        }
        else {
            this.setState({ "errorMessage": data.message });
            setTimeout(() => { this.setState({ "errorMessage": "" }); }, 3000);
            return false;
        }
    }

    increment = async (id, competitionId, price,qty) => {
        let val = $(`.${id}_quanitity`).val();
        val = parseInt(val);
    
        let quantity = +($(`.${id}_quanitity`).val()) + 1;
        let amount = +price * quantity;
        let metaData = {
            'id': id,
            'competitionId': competitionId,
            'amount': amount.toString(),
            'quantity': +quantity 
        }
       
        let valu = await this.saveInMyCart(metaData,competitionId);
        if(valu.error == false){
            let qty = valu.records.quantity
            $(`.${id}_quanitity`).val(qty);
            this.setState({ 'items': $(`.${id}_quanitity`).val() });
        }
    }

    decrement = async (id, competitionId, price) => {
        let val = $(".qty-input").val();
        val = parseInt(val);
        
        let quantity = +($(".qty-input").val()) - 1;
        let amount = +price * quantity;
        let metaData = {
            'id': id,
            'competitionId': competitionId,
            'amount': amount.toString(),
            'quantity': quantity
        }
        let valu = await this.saveInMyCart(metaData, competitionId);
        if(valu.error == false){
        if(val === 1) {
            return false;
        }
        else {
            let qty = valu.records.quantity
            $(`.${id}_quanitity`).val(qty);
            this.setState({ 'items': $(`.${id}_quanitity`).val() });
        }
       }
        

    }

    saveInMyCart = async (metaData, competitionId) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.post(Constant.apiBasePath + 'order/save-in-my-cart', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ CartList: data.data})
            let records = data.data.filter((rec) => rec.competitionId == competitionId)[0]
            console.log(records)
            return { error: false, records}
            // this.setState({ 'successMessage': data.message });
            // setTimeout(() => { window.location.href = '/cart' }, 3000);
        }
        else {
            this.setState({ "errorMessage": data.message });
            setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 3000);
            return { error: true}
        }
    }

    componentDidMount() {
        this.getMyCart();
    }


    render() {
        let { CartList, items } = this.state;
        let cartItems = [];
        let totalPrice = 0;

        if(CartList.length > 0) {
            let total = 0
            cartItems = CartList.map((el,i) => {
                totalPrice = totalPrice + (el.competitionData.perTicketPrice * +el.quantity);
                return <div className="cart-item alert alert-dismissible fade show" role="alert">
                            <div className="media">
                                <div className="media-left">
                                    <div className="product-img">
                                        <img src={ el.competitionData.images[0] } alt=""/>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <div className="d-flex justify-content-between">
                                        <h5><Link to="/product-detail">{ el.competitionData.name }</Link></h5>
                                        <h4 className="price"> <span><i className="fa fa-euro"></i>{ el.competitionData.perTicketPrice }<sub>/Ticket</sub></span> </h4>
                                    </div>
                                    <h4 className="price"> <span><i className="fa fa-euro"></i> { el.competitionData.perTicketPrice } <sub>/Ticket</sub></span> </h4>
                                    <div className="row">
                                        <div className="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-8">
                                            <p className="mb-1">Answer selected</p>
                                            <h5>Standards Board</h5>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 text-end">
                                            <ul className="list-inline d-flex align-items-end justify-content-end">
                                                <li><div className="wishlist-delete"><button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={ ()=> { this.deleteCart(el._id) } }><i className="fa fa-trash-o"></i> </button></div></li>
                                                <li>
                                                    <div className="qty-btn">
                                                        <h6>Ticket Quantity </h6>
                                                        <div id="field1">
                                                            <button type="button" id="sub" className="sub" onClick={ ()=> { this.decrement(el._id, el.competitionId, el.competitionData.perTicketPrice, el.quantity)} }>-</button>
                                                            <input className={`qty-input ${el._id}_quanitity`} type="number" id="1" defaultValue={ el.quantity } min="1" max="10"/>
                                                            <button type="button" id="add" className="add" onClick={ ()=> { this.increment(el._id, el.competitionId, el.competitionData.perTicketPrice, el.quantity) } }>+</button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                       
            });
        }
        function buyNow() {
            let encryptType = window.btoa(2);
            let encryptQuantity = window.btoa(0);
            window.location.href = `/checkout/custom_ids/${encryptType}/${encryptQuantity}`;
        }

        return (
            <>
                <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">My Cart</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="page-header">
                        <div className="d-inline-block">
                            <h3>My Cart <span className="badge">{ CartList.length } </span></h3>
                            <div className="bdr-btm-heading">
                            </div>
                            </div>
                            </div>
                            {
                                (this.state.successMessage)
                                ?
                                <p className='text-success'>{ this.state.successMessage }</p>
                                :
                                (this.state.errorMessage)
                                ?
                                <p className='text-danger'>{ this.state.errorMessage } </p>
                                :
                                ""
                            }
                            
                        
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8"> 
                                <form className="form-cart">
                                    
                                    { cartItems }

                                </form>
                            </div>
                            {
                                (CartList.length > 0)
                                ?
                                    <div className="col-sm-12 col-md-12 col-lg-4 productIncart">
                                        <div className="summary">
                                            <h3>Order Summary</h3>
                                            {/* <div className="form-coupan">
                                                <div className="form-group">
                                                <label>Apply Code</label>
                                                <input type="text" className="form-control form-ctrl" placeholder="Enter"/>
                                                    <button type="submit" className="btn btn-link">Apply</button>
                                                </div>
                                                <h6><a href="#">Apply Code</a></h6>
                                            </div> */}
                                            <div className="Price-Details">
                                                <ul className="list-group">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        Amount ({ items } items).
                                                        <span className="badge"><i className="fa fa-euro"></i> { totalPrice.toFixed(2) }</span>
                                                    </li>
                                                </ul>
                                                    <ul className="list-group totalpay">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            You Pay 
                                                            <span className="badge"><i className="fa fa-euro"></i> { totalPrice.toFixed(2) }</span>
                                                        </li>
                                                    </ul>
                                            </div>
                                            <Link to="#" className="btn btn-warning btn-block btn-lg" onClick={ buyNow }>Proceed to Checkout</Link>
                                        </div>
                                    </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
    