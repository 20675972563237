import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';

export default class BillingInfo extends Component {

    state = {
        BillingInfo: [],
        CartList: [],
        amount: '',
        quantity: 0,
        type: 0,
        successMessage: '',
        errorMessage: '',
        addressTableShow: false,
        disableButtonValue: 'disabled'
    }

    selectAddress = (e) => {
        this.setState({ 'addressId': e.target.value });
    }

    getBillingInfo = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user/billingInfo', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'BillingInfo': data.data });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    createOrder = async () => {
        let token = localStorage.getItem('loginDetails');
        let competitionId = this.props.match.params.id;
        let amount = this.state.amount;
        let quantity = this.state.quantity;
        let type = parseInt(this.state.type);
        let couponCodeId = this.props.match.params.couponCodeId || "";
        let isWallet = $('#flexRadioDefault1').is(":checked");
        let addressId = this.state.addressId;
    
        let CartList = this.state.CartList;

        let metaData = {};
        if(type === 1) {
            metaData = {
                "orders": [{ "competitionId": competitionId, "competitionAmount": amount, "quantity": quantity }],
                "amount": amount,
                "couponCodeId": couponCodeId,
                "isWallet": isWallet,
                "addressId": addressId
            }
        }
        else if(type === 2 && CartList.length > 0) {
            let orders = [];
            for(let i = 0; i < CartList.length; i++) {
                let order = {
                    competitionId: CartList[i].competitionId,
                    competitionAmount: CartList[i].amount,
                    quantity: CartList[i].quantity
                }
                orders.push(order);
            }
            metaData = {
                "orders": orders,
                "amount": CartList[0].amount,
                "couponCodeId": "",
                "isWallet": isWallet,
                "addressId": addressId
            }
        }
        
        var { data } = await Axios.post(Constant.apiBasePath + 'order/create', metaData, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            metaData = {
                'orderId': data.data.orderId,
                'paymentId': data.data.orderId,
                'signature': data.data.orderId,
                "secret": data.data.orderId,
                'type': 2,
                "isWallet": isWallet
            }
            var { data } = await Axios.post(Constant.apiBasePath + 'order/verify-signature', metaData, { headers: { 'token': token }});
            if(data.status === Constant.statusSuccess) {
                // this.setState({ "successMessage": data.message });
                window.$('#thanksModal').modal('show'); 
                // setTimeout(() => { window.location.href = '/orders' }, 3000);
            }
            else {
                this.setState({ "errorMessage": data.message });
                setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 3000);
                return false;
            }
        }
        else {
            this.setState({ "errorMessage": data.message });
            setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 3000);
            return false;
        }
    }

    // get my carts

    getMyCart = async (id) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'order/my-cart', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'CartList': data.data.carts });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        let type = window.atob(this.props.match.params.type);
        let amount = window.atob(this.props.match.params.amount);
        let quantity = window.atob(this.props.match.params.quantity);
       
        if(type == 2) {
            this.getMyCart();
        }

        this.getBillingInfo();
        this.setState({ 'type': type, 'amount': amount, 'quantity': quantity });
    }

    handleCheckbox = () => {
        if($("#invalidCheck").is(":checked") && this.state.BillingInfo.length > 0) {
            this.setState({ 'disableButtonValue': '' });
        }
        else {
            this.setState({ 'disableButtonValue': 'disabled' });
        }
    }

    closeModal = () => {
        window.$('#thanksModal').modal('hide');
        window.location.href = '/orders';
    }
    
    render() {
        let { BillingInfo, CartList  } = this.state;

        let billingList = [];

        if(BillingInfo.length > 0) {
            billingList = BillingInfo.map(address => {
                return  <div className="biling-item alert alert-light fade show" role="alert">
                                <ul className="list-inline d-flex flex-wrap bill-info-user">
                                <li>
                                    <h6>First Name</h6>
                                    <p>{ address.firstName }</p>
                                </li>
                                <li>
                                    <h6>Last Name</h6>
                                    <p>{ address.lastName }</p>
                                </li>
                                <li>
                                    <h6>Street</h6>
                                    <p>{ address.street }</p>
                                </li>
                                <li>
                                    <h6>Town and City</h6>
                                    <p>{ address.cityName }</p>
                                </li>
                                <li>
                                    <h6>Country</h6>
                                    <p>{ address.countryName }</p>
                                </li>
                            </ul>
                            <ul className="list-inline d-flex flex-wrap bill-info-user mb-0">
                                {/* <li>
                                    <h6>Region</h6>
                                    <p>Indian</p>
                                </li> */}
                                <li>
                                    <h6>Post Code</h6>
                                    <p>{ address.postCode }</p>
                                </li>
                                <li>
                                    <h6>Email ID </h6>
                                    <p>{ address.email }</p>
                                </li>
                                <li>
                                    <div className="wishlist-delete d-flex">
                                        <div className="pencil">
                                            <Link to={`/update-billing-info/${ address._id }`} className="btn btn-light" href="#"><img src="/images/pencil.png"/></Link>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="selectAddress" id={ address._id } defaultValue={ address._id } onClick={ this.selectAddress }/>
                                        <label className="form-check-label" for={ address._id }></label>
                                    </div>
                                </li>
                            </ul>
                        </div>
            });
        }

        let quantity = this.state.quantity;
        let amount = this.state.amount;

        if(CartList.length > 0) {
            quantity = 0;
            amount = 0;
            CartList.map(el => {
                quantity += +el.quantity;
                amount += +el.amount 
            });
        
        }

        return (
            <>
                <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                  <li className="breadcrumb-item"><Link to="/cart">My Cart</Link></li>
                                  <li className="breadcrumb-item active">Checkout</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="page-header">
                            <h3>Enter billing info</h3>
                            <div className="bdr-btm-heading"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-7  col-xl-6"> 
                              <div className="summary box-height500">
                                    <div className="row">
                                        <div className="col-8 col-sm-7 col-md-7 col-lg-7">
                                            <h3>Added billing info</h3>
                                        </div>
                                        <div className="col-4 col-sm-5 col-md-5 col-lg-5 text-end">
                                            <h3><Link to="/add-new-billing-info" className='btn btn-dark'>Add New</Link></h3>
                                        </div>
                                    </div>

                                    { billingList }
                                
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-5 col-xl-6 productIncart">
                                    <div className="form-box">
                                        <div className="mode-payment">
                                            <h4>Payment Method</h4>

                                            {
                                                (this.state.successMessage)
                                                ?
                                                <p className='text-success'>{ this.state.successMessage }</p>
                                                :
                                                null
                                            }
                                            {
                                                (this.state.errorMessage)
                                                ?
                                                <p className='text-danger'>{ this.state.errorMessage }</p>
                                                :
                                                null
                                            }

                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                Pay With RO Wallet 
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked="checked"/>
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                Pay with Card
                                                </label>
                                            </div>
                                        </div>
                                       <div className="summary">
                                            <h3>Order Summary</h3>
                                            <div className="Price-Details">
                                                <ul className="list-group">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        Amount ({ quantity } items).
                                                        <span className="badge"><i className="fa fa-euro"></i> { amount }</span>
                                                    </li>
                                                </ul>
                                                <ul className="list-group totalpay">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                                        You Pay 
                                                        <span className="badge"><i className="fa fa-euro"></i> { amount }</span>
                                                    </li>
                                                </ul>
                                            </div>
                                      
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required="" onChange={ this.handleCheckbox }/>
                                                    <label className="form-check-label label-trms" for="invalidCheck">
                                                    Agree to <a  href="#">Terms  &amp; Conditions</a>
                                                    </label>
                                                <div className="invalid-feedback">
                                                    You must agree before submitting.
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-warning btn-block btn-sumbit" onClick={ this.createOrder } disabled={ this.state.disableButtonValue }>Pay with Card <i className="fa fa-euro"></i> { amount }</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Payment successful modal Start */}

                <div className="modal fade" id="thanksModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content feedback-popup">
                            <div className="modal-header justify-content-center">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ this.closeModal }></button>
                            </div>
                            <div className="modal-body">
                                <div className="inner-thanks text-center">
                                    <div className="thankyou-img">
                                        <img src="/images/thanks-img.png"/>
                                    </div>
                                    <h3>Congratulations, you payment successful.</h3>
                                    <p></p>
                                    <div className="form-box row">
                                        <div className="col text-center">
                                            <a href="/orders" className="btn btn-dark btn-block">My orders</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
    