import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs, Autoplay } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import $ from 'jquery';
import slider from './slider';


export default class ProductDetails extends Component {

    state = {
        GetData: {},
        pageNo: 1,
        availableTicketLength: 0,
        wonTicketLength: 0,
        quantity: 1,
        flexSwitchCheckChecked: 1,
        competitionId: '',
        recommendedCompetition: [],
        successMessage: '',
        errorMessage: '',
        competitionOption: "",
        thumbs:null
    }

    handleChange = (e) => {
        let value = parseInt(e.target.value);
        this.setState({ [e.target.id]: (value === 1) ? 2 : 1 });
    }

    handleRangeVal = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    // get competition details

    getDetails = async (id, pageNo) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'competition/details/' + id + '?pageNo=' + pageNo, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'GetData': data.data, quantity: data.data.minTicket,'pageNo': data.data.pageNo, 'availableTicketLength': data.data.lengthOfAvailableTickets, 'wonTicketLength': data.data.lengthOfWonTickets, 'recommendedCompetition': data.data.recommendedCompetition });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    saveUserAnswer = async (option) => {
        let token = localStorage.getItem('loginDetails');
        let metaData = {
            'competitionId': this.props.match.params.id,
            'userChooseOption': option
        }
        console.log(option)
        this.setState({competitionOption: option})
        let { data } = await Axios.post(Constant.apiBasePath + 'competition/saveUserAnswer', metaData, { headers: { 'token': token }});
        // if(data.status === Constant.statusSuccess) {
        //     this.setState({ 'successMessage': data.message });
        //     setTimeout(() => { this.setState({ 'successMessage': "" }) }, 3000);
        // }
        // else {
        //     this.setState({ "errorMessage": data.message });
        //     setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 3000);
        // }
    }

    saveInMyCart = async (option) => {
        let token = localStorage.getItem('loginDetails');
        let GetData = this.state.GetData;
        if(this.state.competitionOption == ""){
            alert("Please Choose one option...!!")
            return false
        }

        let metaData = {
            'competitionId': this.props.match.params.id,
            'amount': (GetData.perTicketPrice * $(".qty-input").val()).toString(),
            'quantity': $(".qty-input").val(),
        }
        let ansuwerValu = {
            'competitionId': this.props.match.params.id,
            "option": this.state.competitionOption
        }
        if(token) {
            let { data } = await Axios.post(Constant.apiBasePath + 'order/save-in-my-cart', metaData, { headers: { 'token': token }});
            if(data.status === Constant.statusSuccess) {
                this.setState({ 'successMessage': data.message });
                window.$('#thanksModal').modal('show'); 
                // setTimeout(() => { window.location.href = '/cart' }, 3000);
            }
            else {
                this.setState({ "errorMessage": data.message });
                setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 3000);
            }
        }
        else {
            metaData = JSON.stringify(metaData);
            localStorage.setItem('addToCartDetails', metaData);
            localStorage.setItem('addToAnuwerCartDetails', JSON.stringify(ansuwerValu));
            window.location.href = '/login';
        }
    }

    buyNow = async () => {
        
        let token = localStorage.getItem('loginDetails');
        let id = this.props.match.params.id;
        let encryptType = window.btoa(1);
        let encryptQuantity = window.btoa(this.state.quantity);

        if(this.state.competitionOption == ""){
            alert("Please Choose one option...!!")
            return false
        }

        let ansuwerValu = {
            'competitionId': this.props.match.params.id,
            "option": this.state.competitionOption
        }

        if(token) {
            let { data } = await Axios.post(Constant.apiBasePath + 'order/buy-now/' + id, {}, { headers: { 'token': token }});
            if(data.status === Constant.statusSuccess) {
                this.props.history.push(`/checkout/${id}/${encryptType}/${encryptQuantity}`);
            }
            else {
                this.setState({ "errorMessage": data.message });
                setTimeout(() => { this.setState({ 'errorMessage': "" }) }, 3000);
            }
        }
        else {
            let metaData = {
                'id': id,
                'encryptType': encryptType,
                'encryptQuantity': encryptQuantity
            }
            metaData = JSON.stringify(metaData);
            localStorage.setItem('buyNowDetails', metaData);
            localStorage.setItem('addToAnuwerCartDetails', JSON.stringify(ansuwerValu));
            window.location.href = '/login';
        }
    }

    increment = () => {
        let val = $(".qty-input").val();
        this.setState({ 'quantity': val });

        val = parseInt(val);
        if(val === parseInt(this.state.GetData.totalTicket)) {
            return false;
        }
        else {
            $(".qty-input").val(val + 1);
            this.setState({ 'quantity': $(".qty-input").val() });
        }
    }

    decrement = () => {
        let val = $(".qty-input").val();
        this.setState({ 'quantity': val });

        val = parseInt(val);
        if(val === 1) {
            return false;
        }
        else {
            $(".qty-input").val(val - 1);
            this.setState({ 'quantity': $(".qty-input").val() });
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({ 'competitionId': id });
        this.getDetails(id, this.state.pageNo);
    }

    closeCartModal = () => {
        window.$('#thanksModal').modal('hide'); 
    }

    checkout = () => {
        window.$('#thanksModal').modal('hide'); 
        this.buyNow();
    }
    
    render() {

        let { GetData, pageNo, availableTicketLength, wonTicketLength, competitionId, recommendedCompetition } = this.state;

        let facnyBoxItems = [];
        let carouselItems = [];
        let about = '';
        let start = 0;

        let type = 'Instant Win';
        let i = 0;

        $(function(){
            $('.carousel-item').click(function(){
                $('.carousel-item.active').removeClass('active');
                $(this).addClass('active');
            });
        });

        let images = [];

        if(GetData) {
            images = GetData.images;
            if(GetData.type == 1) {
                type = 'Normal';
            }

            if(images !== undefined) {
                facnyBoxItems = images.map(el => {
                    return  <a className="fancyZoom" data-fancybox="gallery" href={ el }>
                                <img id="img_zoom" src={ el } data-zoom-image={ el } alt="" className="imgs" />
                            </a> 
                });

                carouselItems = images.map(el => {
                    i++;
                    if(i == 1) {
                        return <li><a href="javascript:void(0)" data-image={ el } data-zoom-image={ el } className="carousel-item active"> <img  src={ el } className="customThumnnail" data-large-image={ el }/></a></li>
                    }
                    else {
                        return <li ><a href="javascript:void(0)" data-image={ el } data-zoom-image={ el } className="carousel-item"> <img  src={ el } className="customThumnnail" data-large-image={ el }/></a></li>
                    }
                })
            }
            start = GetData.start;
        }

        if(GetData.about !== undefined) {
            about = parse(GetData.about);
        }

        let availableTickets = [];
        let wonTickets = [];     
        let availableTicketsDiv = [];
        let wonTicketsDiv = [];   

        if(GetData.availableTickets !== undefined) {
            availableTickets = GetData.availableTickets;
            wonTickets = GetData.wonTickets;

            if(availableTickets.length > 0) {
                availableTicketsDiv = availableTickets.map(el => {
                    return <div className="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                <div className="Prizes-shape">
                                    <div className="Prizes-dec">
                                        <h6>INSTANT WIN</h6>
                                        <h4>{ el.ticketNumber }</h4>
                                        <p>{ el.prizeName }</p>
                                    </div>
                                </div>
                            </div>
                });
            }

            if(wonTickets.length > 0) {
                wonTicketsDiv = wonTickets.map(el => {
                    return <div className="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                <div className="Prizes-shape">
                                    <div className="Prizes-dec">
                                        <h6>INSTANT WIN</h6>
                                        <h4>{ el.ticketNumber }</h4>
                                        <p>{ el.prizeName }</p>
                                    </div>
                                </div>
                            </div>
                });
            }
        }

        let pagination = [];
        if(this.state.flexSwitchCheckChecked === 1 && availableTicketLength > 1) {
            let previous = 0;
            let next = 0;
            let customLength = 0

            if (pageNo !== 0) {
                previous = pageNo - 1;
                next = pageNo + 1;
            }

            if (availableTicketLength !== 0) {
                customLength = availableTicketLength;
            }

            let previousList = <li className="page-item"><a className="page-link page-link-prev" href="#" tabindex="-1" aria-disabled="true"><i className="fa fa-angle-left"></i></a></li>
            if(pageNo > 1) {
                previousList = <li className="page-item" onClick={() => this.getDetails(competitionId, previous)}><a className="page-link page-link-prev" href="#" tabindex="-1" aria-disabled="true"><i className="fa fa-angle-left"></i></a></li>
            }

            pagination.push(previousList);
            // for(let i = 1; i <= availableTicketLength; i++) {
            //     pagination.push(<li className="page-item" onClick={() => this.getDetails(competitionId, i)}><a className="page-link" href="#">{ i }</a></li>)
            // }
            pagination.push(<li className="page-item"><a className="page-link page-link-next" href="#">{pageNo}</a></li>)
           
            let nextList = <li className="page-item"><a className="page-link page-link-next" href="#"><i className="fa fa-angle-right"></i></a></li>
            if((pageNo < customLength)) {
                nextList = <li className="page-item" onClick={() => this.getDetails(competitionId, next)}><a className="page-link page-link-next" href="#"><i className="fa fa-angle-right"></i></a></li>
            }
            pagination.push(nextList);

        }
        else {
            if(wonTicketLength > 1) {
                let previous = 0;
                let next = 0;
                let customLength = 0

                if (pageNo !== 0) {
                    previous = pageNo - 1;
                    next = pageNo + 1;
                }

                if (wonTicketLength !== 0) {
                    customLength = wonTicketLength;
                }

                let previousList = <li className="page-item"><a className="page-link page-link-prev" href="#" tabindex="-1" aria-disabled="true"><i className="fa fa-angle-left"></i></a></li>
                if(pageNo > 1) {
                    previousList = <li className="page-item" onClick={() => this.getDetails(competitionId, previous)}><a className="page-link page-link-prev" href="#" tabindex="-1" aria-disabled="true"><i className="fa fa-angle-left"></i></a></li>
                }

                pagination.push(previousList);
                for(let i = 1; i <= wonTicketLength; i++) {
                    pagination.push(<li className="page-item" onClick={() => this.getDetails(competitionId, i)}><a className="page-link" href="#">{ i }</a></li>)
                }

                let nextList = <li className="page-item"><a className="page-link page-link-next" href="#"><i className="fa fa-angle-right"></i></a></li>
                if((pageNo < customLength)) {
                    nextList = <li className="page-item" onClick={() => this.getDetails(competitionId, next)}><a className="page-link page-link-next" href="#"><i className="fa fa-angle-right"></i></a></li>
                }
                pagination.push(nextList);
            }
        }

        let recommendedCompetitionList = [];
        if(recommendedCompetition.length > 0) {
            recommendedCompetitionList = recommendedCompetition.map(el => {
                let date = '';
                if(el.endDate) {
                    date = new Date(el.endDate).toString();
                    date = date.split(" ");
                    date = date[2] + " " + date[1] + " " + date[3];
                }

                return <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                            <div className="item-box">
                                <div className="thumbnail tmb">
                                    <img src={ el.images[0] } alt=""/>
                                </div>
                                <div className="item-box-dec">
                                    {
                                        (date)
                                        ?
                                        <p className="time">End { date } At { el.endTimeAMPM }</p>
                                        :
                                        null
                                    }
                                    <h5>{ el.name }</h5>
                                    <div className="row align-items-center">
                                        <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 pr-0">
                                            <p className="price mb-0"> <i className="fa fa-euro"></i> { el.perTicketPrice } <sub>/Ticket</sub></p>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 text-end">
                                            <a href={`/product-details/${el._id}`} className="btn btn-block btn-warning">{ el.competitionType } </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            })
        }

        // const images = [
        //     'https://rodevcompetition.s3.ap-south-1.amazonaws.com/competition/images/images_1695127284975.jpeg',
        //     'https://rodevcompetition.s3.ap-south-1.amazonaws.com/competition/images/images_1696406545781.png',
        //     'https://rodevcompetition.s3.ap-south-1.amazonaws.com/competition/images/images_1695127284975.jpeg',
        // ];


        return(
            <>
                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="title">
                                        <h2>Ongoing Competitions </h2>
                                        <div className="bdr-btm-heading">

                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-7">
                                <div className="preview">
                                    
                                    <div class="catalog-view_op1">
                                        <div class="detail-gallery">
                                            <div class="thumb-product mid">		
                                                <div id="facnyBoxitem">
                                                    <Swiper
                                                        loop={true}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false,
                                                        }}
                                                        modules={[Navigation, Thumbs, Autoplay]}
                                                        grabCursor={true}
                                                        spaceBetween={10}
                                                        thumbs={{swiper: this.state.thumbs && !this.state.thumbs.destroyed ? this.state.thumbs : null}}
                                                        navigation={false}
                                                    >
                                                        {
                                                            (images != undefined)
                                                            ?
                                                                images.map((src, idx) => <SwiperSlide key={idx} id={`swiper-slider-thum_${idx}`}>
                                                                <a className="fancyZoom" data-fancybox="gallery" href={ src }>
                                                                    <img id="img_zoom" src={ src } data-zoom-image={ src } alt="" className="imgs" />
                                                                </a> 
                                                                </SwiperSlide>)
                                                            :
                                                            null
                                                        }
                                                    </Swiper>
                                                </div>		
                                            </div> 
                                            <div class="gallery-control nonCircular">
                                                <div class="thumblist">
                                                <Swiper
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                      }}
                                                    modules={[Navigation, Thumbs, Autoplay]}
                                                    className="swiper-v"
                                                    direction={'vertical'}
                                                    grabCursor={false}
                                                    spaceBetween={5}
                                                    slidesPerView={5}
                                                    touchRatio= {0.2}
                                                    slideToClickedSlide= {true}
                                                    onSwiper={(e) => this.setState({thumbs:e})}
                                                    navigation={true}
                                                >
                                                    {
                                                        (images != undefined)
                                                        ?
                                                            images.map((src, idx) => <SwiperSlide key={idx}>
                                                                <img src={src}/>
                                                            </SwiperSlide>)
                                                        : 
                                                        null
                                                    }
                                                </Swiper>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                               
                                    {/* <div class="catalog-view_op1">
                                        <div class="detail-gallery">
                                            <div class="thumb-product mid">		
                                                <div id="facnyBoxitem">
                                                    { facnyBoxItems }
                                                </div>		
                                            </div> 
                                            <div class="gallery-control nonCircular">
                                                <div class="carousel thumblist">
                                                    <ul class="list-none" id="leftthumbslidercontainer">
                                                        { carouselItems }
                                                    </ul>
                                                </div>
                                                <div class="control-button-gallery text-center">
                                                    <a href="#" class="prevMe"><i class="fa fa-angle-up"></i></a>
                                                    <a href="#" class="nextMe"><i class="fa fa-angle-down"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-5 details">
                                <ul className="d-flex flex-wrap list-inline info-list">
                                    <li><img src="/images/Ticket-Star-orange.png"/> { GetData.totalTicket } tickets</li>
                                    <li><img src="/images/king-orange.png"/>{ GetData.totalWinners }  Winner</li>
                                    <li><img src="/images/user-icon0.png"/> { GetData.entriesPerPerson } Entries per person</li>
                                </ul>
                                <h3 className="product-title">{ GetData.name }</h3>
                                <h4 className="price"> <span><i className="fa fa-euro"></i> { GetData.perTicketPrice } <sub>/Ticket</sub></span> </h4>
                                <div className="row">
                                    <div className="col-10 col-sm-6 col-md-6 col-lg-8">
                                        <div className="progress-bar-item">
                                            <div className="progress">
                                                <div className="progress-bar bg-warning" role="progressbar" style={{width: GetData.percentOfAvailableTickets }} aria-valuenow={ GetData.percentOfAvailableTickets } aria-valuemin="0" aria-valuemax="100"></div>
                                                <div className='remaining-text text-center w-100'>{ GetData.percentOfAvailableTickets }% Remaining</div>
                                                </div>
                                                <p>Ticket Sold: <span className="text-dark">{ GetData.soldTickets }</span> / { GetData.totalTicket }</p>
                                            </div>
                                        </div>
                                    </div>
                                
                                    {
                                        (availableTickets.length > 0 && start === 1)
                                        ?
                                            <>
                                                <div className="details-dec">
                                                    <h4>Question & Answers</h4>
                                                    <h5 className='text-secondary'>{ GetData.question }</h5>
                                                    {
                                                        (this.state.successMessage)
                                                        ?
                                                        <p className='text-success'>{ this.state.successMessage }</p>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        (this.state.errorMessage)
                                                        ?
                                                        <p className='text-danger'>{ this.state.errorMessage }</p>
                                                        :
                                                        null
                                                    }
                                                    <ul className="list-inline d-flex flex-wrap answer-ul">
                                                        <li><a className="btn btn-success" href="#" onClick={ ()=> { this.saveUserAnswer('A') }}>{ GetData.option1 }</a></li>
                                                        <li><a className="btn btn-success" href="#" onClick={ ()=> { this.saveUserAnswer('B') }}>{ GetData.option2 }</a></li>
                                                        <li><a className="btn btn-success" href="#" onClick={ ()=> { this.saveUserAnswer('C') }}>{ GetData.option3 }</a></li>
                                                    </ul>
                                                </div>
                                                <div className="row">
                                                    <div className="col-8 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                                        <div className="sliders_step-box">
                                                            <div className="extra-controls form-inline">
                                                                <div class="sliders_step1">
                                                                <input type="range" id="quantity" min={ GetData.minTicket } max={ GetData.maxTicket } value={ this.state.quantity } onChange={ this.handleRangeVal }/>
                                                                </div>
                                                                <div id="time-range">
                                                                    <span className="slider-time">Min { GetData.minTicket }</span>
                                                                    <span className="slider-time2">Max { GetData.maxTicket }</span>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="detail-cartbtns">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-4 col-md-4 pe-0">
                                                            <div className="qty-btn">
                                                            <h6>Ticket Quantity </h6>
                                                            <div id="field1">
                                                                <button type="button" id="sub" className="sub" onClick={ this.decrement }>-</button>
                                                                <input className="qty-input" type="number" id="1" value={ this.state.quantity } onChange={(e) => this.setState({quantity: +e.target.value})} min="1" max="10"/>
                                                                <button type="button" id="add" className="add" onClick={ this.increment }>+</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-4">
                                                        <Link to="#" className="btn btn-dark btn-block btn-lg" onClick={ this.saveInMyCart }> Add To Cart</Link>
                                                        {/* <a href="#thanksModal" data-bs-toggle="modal" className="btn btn-dark btn-block btn-lg" onClick={ this.openCartModal }> Add To Cart</a> */}
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-4">
                                                        <Link to="#" className="btn btn-warning btn-block btn-lg" onClick={ this.buyNow }>Buy Now</Link>
                                                    </div>
                                                
                                                </div>
                                                </div>
                                            </>
                                        : 
                                            null
                                    }
                            </div>
                        </div>
                    </div>   
                </section>

                <section className="wrap wrap-top0">
                    <div className="container-fluid">
                        {/* <ul className="nav nav-pills  dec-nav-tab" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-Description" type="button" role="tab" aria-controls="pills-home" aria-selected="true">About the competitions</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-Additionalinfo" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Terms & Conditions</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-Description" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="details-dec">
                                { about }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-Additionalinfo" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="details-dec">
                                    { GetData.description }
                                </div>
                            </div>
                        </div> */}
                    <Tabs>
                        <TabList>
                            <Tab>About the competitions</Tab>
                            <Tab>Other product information</Tab>
                        </TabList>
                        <TabPanel>
                            <p>{ about }</p>
                        </TabPanel>
                        <TabPanel>
                            <p>{ GetData.description }</p>
                        </TabPanel>
                    </Tabs>

                        <div className="details-dec">
                        <p><Link className='btn btn-link ps-0' to="/terms-and-conditions">About our terms & condition</Link></p>
                        </div>
                        <div className="faq-panel WinPrizeAccordion">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    {
                                        (GetData.type === 2)
                                        ?
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            { type } Prizes
                                            </button>
                                        </h2>
                                        :
                                        null
                                    }
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="AvailableWon-switch">
                                        <div className="form-check form-switch">
                                            {
                                                (this.state.flexSwitchCheckChecked === 1)
                                                ?
                                                   <label className="form-check-label" for="flexSwitchCheckChecked" id="available">Available</label>
                                                :
                                                    <label className="form-check-label" for="flexSwitchCheckChecked" id="won">Won</label>
                                            }
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultValue={this.state.flexSwitchCheckChecked} onChange={ this.handleChange }/>
                                            {/* <label className="form-check-label" for="flexSwitchCheckChecked" id="won">Won</label> */}
                                        </div>
                                    </div>
                                    
                                    {
                                        (this.state.flexSwitchCheckChecked === 1)
                                        ?
                                            <div className="availableoffer">
                                                <div className="row text-center">

                                                    { availableTicketsDiv }

                                                </div>
                                            </div>
                                        :
                                            <div className="wonoffer">
                                                <div className="row text-center">

                                                    { wonTicketsDiv }

                                                </div>
                                            </div>
                                    }
                                    
                                    
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center navigation-number">
                                            {
                                                (pagination.length > 0)
                                                ?
                                                    <>
                                                        { pagination }
                                                    </>
                                                :
                                                    ""
                                            }
                                        </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    </div>
                        </div>
		            </div>
                </section>

                <section className="wrap competitions-wrap wrap-top0">
                    <div className="container-fluid">
                        <hr/>    
                        <div className="slide-heading">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="title">
                                        <h2>Recommended Competition </h2>
                                        <div className="bdr-btm-heading">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            { recommendedCompetitionList }   

                        </div>
                        {/* <div className="slide-heading">
                            <div className="row text-center">
                                <div className="col-md-12 col-sm-12 col-12 view-btn">
                                    <a className="btn btn-outline-dark" href="#">View All <i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>

                {/* Thanks modal Start */}

                <div className="modal fade" id="thanksModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content feedback-popup">
                            <div className="modal-header justify-content-center">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ this.closeCartModal }></button>
                            </div>
                            <div className="modal-body">
                                <div className="inner-thanks text-center">
                                    <div className="thankyou-img">
                                        <img src="/images/thanks-img.png"/>
                                    </div>
                                    <h3>Congratulations!</h3>
                                    <p>{ $(".qty-input").val() } item added to basket!</p>
                                    <div className="form-box row">
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <a href="/cart" className="btn btn-dark btn-block">Keep Shopping</a>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <button type="button" className="btn btn-warning btn-block" onClick={ this.checkout }>Chechout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Review modal Start */}

                <div className="modal fade" id="ratingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content feedback-popup">
                            <div className="modal-header justify-content-center">
                                <h5 className="modal-title text-center" id="exampleModalLabel">Rating &amp; Review </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-box-login">
                                    <div className="rating-stars">
                                        <i className="fa fa-star checked"></i>
                                        <i className="fa fa-star checked"></i>
                                        <i className="fa fa-star checked"></i>
                                        <i className="fa fa-star checked"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                
                                    <div className="form-box">
                                        <div className="form-group">
                                            <label>Your feedback</label>
                                            <textarea className="form-control form-ctrl" rows="6"></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-dark btn-block">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}