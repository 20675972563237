import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';

export default class Header extends Component {

    state = {
        UserDetails: {},
        NotificationList: [],
        length: 0,
        count: 0
    }

    isUserLogin = () => {
        let isLogin = localStorage.getItem('loginDetails')
        return isLogin != null ? true : false
    }

    logout = () => {
        localStorage.removeItem('loginDetails');
        window.location.href = '/login';
    }

    getProfileDetails = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user/getProfileDetails', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            let quantity = 0
            data.data.cart.map((cart) => {
                quantity = quantity + cart.quantity
            })
            this.setState({ 'UserDetails': {...data.data, cart: quantity} });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    userNotification = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user-notification?limit=2', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'NotificationList': data.data.list, 'count': data.data.count, 'length': data.data.length });
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getProfileDetails();
        this.userNotification();
    }


    render() {
        const { UserDetails, NotificationList, count, length } = this.state;
        let walletAmount = 0;
        if(UserDetails.name !== undefined) {
            walletAmount = UserDetails.wallet;
        }

        let notifications = [];
        if(NotificationList.length > 0) {
            notifications = NotificationList.map(el => {
                return  <li>
                            <h6 className="d-flex justify-content-between align-items-center">{ el.title } { (el.readStatus) ? null : <span>New</span> }</h6>
                            <p>{ el.description }</p>
                            <p className="notification-date"><i className="fa fa-clock-o"></i>{ el.createdAt }</p>
                        </li>
            });
        }

        return (
            <header className="fixedHeader">
                <nav id="navbar-example2" className="navbar sticky-top navbar-expand-lg navbar-light bg-dark">
                    <div className="container-fluid">
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarMenu" aria-controls="offcanvasExample">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link className="navbar-brand" to="/"><img src="/images/logo.png"/></Link>
                        <div className="m-auto offcanvas offcanvas-start offcanvas-menu" tabindex="-1" id="offcanvasNavbarMenu" aria-labelledby="offcanvasExampleLabel">
                            <div className="d-flex ms-auto">
                            <ul className="navbar-nav ms-auto flex-nowrap d-flex">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Competitions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/our-winners">Our Winners</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/draws">Draws</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/entry-list">Entry List</Link>
                                </li>
                            </ul>
                            {this.isUserLogin() &&
                            <ul className="navbar-nav ms-auto flex-nowrap d-flex mobile-nav-wallet">
                                <li className="nav-item nav-item-wallet">
                                    <Link className="nav-link" to="/wallet"><img src="/images/Wallet-yellow.png"/> <city> My Wallet </city>
                                    <span> ${ walletAmount } </span> </Link> 
                                </li>
                                <li className="nav-item">
                                   <Link className="nav-link" to="/cart"><img src="/images/Bag-yellow.png"/> <span> My Cart </span>
                                   <br /><span> { UserDetails.cart ? UserDetails.cart : 0  } </span> </Link> 
                                   
                                </li>
                            </ul> }
                        </div>
                    </div>
                        
                    <ul className="navbar-nav ms-auto flex-nowrap d-flex mobile-nav">
                    {this.isUserLogin() && <li className="nav-item nav-item-notification dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" to="/notification"><img src="/images/Notification.png"/>  </Link> 
                            <ul className="dropdown-menu dropdown-rgt" aria-labelledby="navbarDropdown">
                                <li className="dropdown-header"><h5> Notification <span>{ count }</span></h5></li>
                                { notifications }
                                {
                                    (length > 1)
                                    ?
                                    <li><Link className="dropdown-item text-center" to="/notification">View all Notification</Link></li>
                                    : 
                                    null
                                }
                            </ul>
                        </li> }
                        {!this.isUserLogin() &&  
                        <><li style={{marginRight: 10}}><Link to="/login" className="btn btn-warning btn-gradent btn-block btn-sumbit">login</Link></li>
                        <li><Link to="/register" className="btn btn-warning btn-gradent btn-block btn-sumbit">Register</Link></li></>}
                        
                        {this.isUserLogin() && 
                        <li className="nav-item login-drop dropdown">
                            <Link className="nav-link login dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false" to="#"><img src="/images/user-pic.png"/></Link> 
                            <ul className="dropdown-menu dropdown-rgt" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/profile"><img src="/images/Profile-icon.png"/> My Account</Link></li>
                                <li><Link className="dropdown-item" to="/orders"><img src="/images/Bag-icon.png"/>  My Orders</Link> </li>
                                <li><Link className="dropdown-item" to="/wallet"><img src="/images/Wallet-icon.png"/>  Wallet</Link> </li>
                                <li><Link className="dropdown-item" to="/preset-password"><img src="/images/Wallet-icon.png"/>  Reset Password</Link> </li>
                                <li onClick={ () => this.logout() }><Link className="dropdown-item" to="/login"><img src="/images/Logout.png"/>  Logout</Link> </li>
                            </ul> 
                        </li>}
                    </ul>
                </div>
            </nav>
        </header>
        )
    }
}

    
    
   