import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import $ from 'jquery';


export default class Register extends Component {

    state = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        successMessage: '',
        errorMessage: '',
        errorName: '',
        errorEmail: '',
        errorPassword: '',
        errorConfirmPassword: '',
        passwordInputType: false,
        resetpasswordInputType: false,
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        // input form validation
        this.inputFieldValidation();

        let metaData = { 
            // 'name': this.state.name,
            'email': this.state.email,
            'password': this.state.password,
            'confirmPassword': this.state.confirmPassword
        }

        if(metaData.email === '' || metaData.password === '' || metaData.confirmPassword === '') {
            return false;
        }

        let { data } = await Axios.post(Constant.apiBasePath + 'user/signup', metaData);
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            if(data.message === Constant.userExist) {
                setTimeout(() => { window.location.href = '/login' }, 3000);
            }
            else {
                setTimeout(() => { window.location.href = '/otp/' + data.data + '/1' }, 3000);
            }
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    inputFieldValidation = () => {
        let name = this.state.name;
        let email = this.state.email;
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;

        // if(name === '') {
        //     this.setState({ 'errorName': 'Name is required!' });
        // }
        // else {
        //     this.setState({ 'errorName': '' });
        // }

        if(email === '') {
            this.setState({ 'errorEmail': 'Email is required!' });
        }
        else {
            this.setState({ 'errorEmail': '' });
        }
       
        if(password === '') {
            this.setState({ 'errorPassword': 'Password is required!' });
        }
        else {
            this.setState({ 'errorPassword': '' });
        }

        if(confirmPassword === '') {
            this.setState({ 'errorConfirmPassword': 'Confirm password is required!' });
        }
        else {
            this.setState({ 'errorConfirmPassword': '' });
        }
        if(password !== confirmPassword) {
            this.setState({ 'errorConfirmPassword': 'password and confirm password are mismatch!' });
        }
        else {
            this.setState({ 'errorConfirmPassword': '' });
        }
    }
    
    mouseOver = () => {
        this.setState({ 'passwordInputType': !this.state.passwordInputType });
    }

    resetPasswordmouseOver = () => {
        this.setState({ 'resetpasswordInputType': !this.state.resetpasswordInputType });
    }


    render() {

        return(
            <section className="wrap">
                <div className="container">
                    <div className="backbtn">
                        <Link to="/login"><img src="images/arrow-a-left.png"/> Back to login</Link>
                    </div>
                    <div className="form-box form-box-login">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                                <div className="login-white-box">
                                    <div className="title text-center">
                                        <h3 className="mb-3">Create your Account</h3>
                                        <p>Create account to start using  Ro Competition</p>
                                        {
                                            (this.state.successMessage)
                                            ?
                                            <p className="text-success">{ this.state.successMessage }</p>
                                            :
                                            (this.state.errorMessage)
                                            ?
                                            <p className="text-danger"> { this.state.errorMessage }</p>
                                            :
                                            ""
                                        }
                                    </div>
                                    <div className="signp-with-social">
                                        <a href="#"><img src="images/fb-icon.png"/> <span>Facebook</span></a>
                                        <a href="#"><img src="images/google-icon.png"/> <span>Google</span></a>
                                    </div>
                                    <div className="or"><span>Or</span></div>
                                    <form>
                                        {/* <div className="form-group">
                                            <input type="text" id="name" onChange={this.handleChange} className="form-ctrl form-control" placeholder="Enter Your Full Name"/>   
                                            <p className='text-danger'>{ this.state.errorName }</p>
                                        </div> */}
                                        <div className="form-group">
                                            <input type="email" id="email" onChange={this.handleChange} className="form-ctrl form-control" placeholder="Email Id*"/>  
                                            <p className='text-danger'>{ this.state.errorEmail }</p>
                                        </div>
                                        <div className="form-group">
                                            <input type={ (this.state.passwordInputType) ? 'text' : 'password' } id="password" onChange={this.handleChange} className="form-ctrl form-control" placeholder="Create password"/>   
                                            <p className='text-danger'>{ this.state.errorPassword }</p>   
                                            <div className="form_icon">
                                            <span toggle="#confirmPassword" className="fa fa-fw field-icon toggle-password fa-eye" onClick={ this.mouseOver }></span>
                                        </div> 
                                        </div>
                                        <div className="form-group">
                                            <input type={ (this.state.resetpasswordInputType) ? 'text' : 'password' }  id="confirmPassword" onChange={this.handleChange} className="form-ctrl form-control" placeholder="Confirm password"/> 
                                            <p className='text-danger'>{ this.state.errorConfirmPassword }</p>   
                                        <div className="form_icon">
                                            <span toggle="#confirmPassword" className="fa fa-fw field-icon toggle-password fa-eye" onClick={ this.resetPasswordmouseOver }></span>
                                        </div> 
                                        </div>
                                
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-warning btn-gradent btn-block btn-sumbit" onClick={this.handleSubmit}>Create account</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}