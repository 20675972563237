import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css'

export default function SampleSlider() {
    const [thumbs, setThumbs] = useState(null)
    const images = [
        'https://rodevcompetition.s3.ap-south-1.amazonaws.com/competition/images/images_1695127284975.jpeg',
        'https://rodevcompetition.s3.ap-south-1.amazonaws.com/competition/images/images_1696406545781.png',
        'https://rodevcompetition.s3.ap-south-1.amazonaws.com/competition/images/images_1695127284975.jpeg',
    ];
    return (
        <>
            <Swiper
                loop={true}
                modules={[Navigation, Thumbs]}  
                thumbs={{swiper: thumbs && !thumbs.destroyed ? thumbs : null}}
                navigation
            >
                {images.map((src, idx) => <SwiperSlide key={idx}><img src={src} /></SwiperSlide>)}
            </Swiper>
            <Swiper
                slidesPerView={3}     
                onSwiper={setThumbs}
            >
                {images.map((src, idx) => <SwiperSlide key={idx}><img src={src} /></SwiperSlide>)}
            </Swiper>
        </>
    )
}