// import package and components

import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import UserAccess from './UserAccess';
import Header from './views/Header';
import Footer from './views/Footer';
import Home from './views/Home';
import AddUpdateAddress from './views/AddUpdateAddress';
import AddUpdateBillingInfo from './views/AddUpdateBillingInfo';
import BillingInfo from './views/BillingInfo';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import Competition from './views/Competition';
import ContactUS from './views/ContactUS';
import EntryList from './views/EntryList';
import FAQs from './views/FAQs';
import Feedback from './views/Feedback';
import ForgotPassword from './views/ForgotPassword';
import Login from './views/Login';
import Notification from './views/Notification';
import Order from './views/Order';
import OTP from './views/OTP';
import OurWinners from './views/OurWinners';
import ProductDetails from './views/ProductDetails';
import Profile from './views/Profile';
import RefundPolicy from './views/RefundPolicy';
import Register from './views/Register';
import ResetPassword from './views/ResetPassword';
import TermCondition from './views/TermCondition';
import ThankYou from './views/ThankYou';
import Wallet from './views/Wallet';
import EditProfile from './views/EditProfile';
import OrderDetails from './views/OrderDetails';
import Draws from './views/Draws';
import DatePolicy from './views/DatePolicy';
import PaymentPolicy from './views/PaymentPolicy';
import Disclaimer from './views/Disclaimer';
import AboutUS from './views/AboutUs';
import ProfileResetPassword from './views/ProfileResetPassword'

/* End */

class App extends Component {


    render() {


        return (
            <div>
                <Router>
                    <Switch>
                        <Route component={DefaultContainer}/>
                    </Switch>
                </Router>
            </div>
        )
    }
}

//To be change in to default router
const DefaultContainer = () => (
    <div>
        <Header/>
            <Route exact strict path="/" component={Home} />  
            <UserAccess exact strict path="/add-address" component={AddUpdateAddress} />
            <UserAccess exact strict path="/update-address/:id" component={AddUpdateAddress} />            
            <UserAccess exact strict path="/add-new-billing-info" component={AddUpdateBillingInfo} />   
            <UserAccess exact strict path="/update-billing-info/:id" component={AddUpdateBillingInfo} />            
            <Route exact strict path="/billing-info/:id/:amount/:quantity/:type/:couponCodeId" component={BillingInfo} />   
            <Route exact strict path="/billing-info/:id/:amount/:quantity/:type" component={BillingInfo} />            
            <UserAccess exact strict path="/cart" component={Cart} />            
            <UserAccess exact strict path="/checkout/:id/:type/:quantity" component={Checkout} />            
            <UserAccess exact strict path="/competitions/:id" component={Competition} />            
            <Route exact strict path="/contact-us" component={ContactUS} />            
            <Route exact strict path="/entry-list" component={EntryList} />            
            <Route exact strict path="/faqs" component={FAQs} />            
            <Route exact strict path="/feedback" component={Feedback} />            
            <Route exact strict path="/forgot-password" component={ForgotPassword} />            
            <Route exact strict path="/login" component={Login} />            
            <UserAccess exact strict path="/notification" component={Notification} />            
            <UserAccess exact strict path="/orders" component={Order} />            
            <UserAccess exact strict path="/order-details/:id" component={OrderDetails} />
            <Route exact strict path="/otp/:token/:type" component={OTP} />            
            <Route exact strict path="/our-winners" component={OurWinners} />            
            <Route exact strict path="/product-details/:id" component={ProductDetails} />            
            <UserAccess exact strict path="/profile" component={Profile} />            
            <Route exact strict path="/refund-policy" component={RefundPolicy} />            
            <Route exact strict path="/register" component={Register} />            
            <Route exact strict path="/reset-password/:token" component={ResetPassword} />
            <Route exact strict path="/terms-and-conditions" component={TermCondition} />            
            <Route exact strict path="/thank-you" component={ThankYou} /> 
            <UserAccess exact strict path="/wallet" component={Wallet} />   
            <UserAccess exact strict path="/edit-profile" component={EditProfile} />    
            <UserAccess exact strict path="/preset-password" component={ProfileResetPassword} />    
            <Route exact strict path="/draws" component={Draws} />   
            <Route exact strict path="/terms-of-user" component={DatePolicy} />  
            <Route exact strict path="/payment-policy" component={PaymentPolicy} />  
            <Route exact strict path="/disclaimer" component={Disclaimer} />  
            <Route exact strict path="/about-us" component={AboutUS} />  
                    
        <Footer/>
    </div>
)


export default App;