import React, { Component } from 'react';

export default class ContactUS extends Component {

    render() {
        return (
            <>
               <section class="inr-wrap inr-wrap-banner">
                    <img src="images/contact-bnr.jpg"/>
                    <div class="inr-wrap-overlay">
                        <div class="container">
                            <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="title text-center">
                                <h2 class="text-white">Contact Us</h2>
                            </div>
                            </div>
                                
                            </div>
                            </div>
                    </div>
                </section>
                <section class="wrap">
                        <div class="container-fluid">
                            <div class="contact-sec">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-5">
                                <div class="title">
                                    <h2>Contact Us</h2>
                                    <div class="row">
                                <div class="col-12 col-sm-6 col-md-10 col-lg-10  col-xl-10">
                                    <p>Forget the old rules. You can have the best people. Right now You can have the bes people You  the best  </p>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="media fotr-media">
                                    <div class="media-left">
                                        <div class="fotr-media-icon"><i class="fa fa-phone"></i></div>
                                        </div>
                                    <div class="media-body">
                                        <h6>Our Contact Number  </h6>
                                        <p><a href="tel:+011 6542021546">+011 6542021546</a></p>
                                        </div>
                                    </div>
                                    <div class="media fotr-media">
                                    <div class="media-left">
                                        <div class="fotr-media-icon"><i class="fa fa-envelope"></i></div>
                                        </div>
                                    <div class="media-body">
                                        <h6>Email ID   </h6>
                                        <p><a href="mailto:rocompetition@gmail.com">rocompetition@gmail.com</a></p>
                                        </div>
                                    </div>
                                    <div class="media fotr-media">
                                    <div class="media-left">
                                        <div class="fotr-media-icon"><i class="fa fa-map-marker"></i></div>
                                        </div>
                                    <div class="media-body">
                                        <h6>Office Address   </h6>
                                        <p>8502 Preston Rd. Inglewood, Maine 98380</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-7">
                                <div class="contact-form">
                                    <div class="title">
                                    <h3>Leave query with us</h3>
                                    </div>
                                    <div class="row">
                                    <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group">
                                    <label>Full Name</label>
                                        <input type="text" class="form-control form-ctrl" placeholder="Type here"/>
                                    </div>
                                        </div>
                                    <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group">
                                    <label>Number</label>
                                        <input type="text" class="form-control form-ctrl" placeholder="Type here"/>
                                    </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                    <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group">
                                    <label>Email</label>
                                        <input type="text" class="form-control form-ctrl" placeholder="Type here"/>
                                    </div>
                                        </div>
                                    <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group">
                                    <label>City Name</label>
                                        <input type="text" class="form-control form-ctrl" placeholder="Type here"/>
                                    </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                    <label>Enter Message</label>
                                        <textarea rows="6" class="form-control form-ctrl" placeholder="Type here"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-warning btn-block">Submit</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                    </div>
                </section>
            </>
        )
    }
}
    