import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';


export default class TermCondition extends Component {

    state = {
        PageDetails: {},
        successMessage: '',
        errorMessage: ''
    }

    // get cms data

    getCMSData = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'get-common-data?shortName=terms_conditions', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'PageDetails': data.data });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getCMSData();
    }


    render() {
        let { PageDetails } = this.state;
    
        let name = 'Terms & Conditions';
        let content = '';
        if(PageDetails.name !== undefined) {
            name = PageDetails.name;
            content = PageDetails.content;
        }
        return(
            <section class="wrap-40">
                <div class="container-fluid">
                    <div class="page-header border-0 pb-0 mb-2">
                    <div className="d-inline-block">
                        <h3>{ name }</h3>
                        <div class="bdr-btm-heading"></div>
                        </div>
                    </div>
                    <div class="txt_dec">
                        <p> { content }</p>
                    </div>
                </div>
            </section>
        )
    }
}