import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import $ from 'jquery';


export default class EntryList extends Component {

    state = {
        DataList: [],
        successMessage: '',
        errorMessage: ''
    }

    // get entry list

    getEntryList = async (id) => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'competition/getEntryList', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'DataList': data.data });
        }
        else {
            this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getEntryList();
    }

    exportData = async (competitionId) => {
        try {
            let token = localStorage.getItem('loginDetails');
    
            var {data} = await Axios.get(Constant.apiBasePath + 'prize/entry-list/export/' + competitionId, { headers: { 'token': token }});
            if(data.status === Constant.statusSuccess) {
                var csvString = data.data;
                var universalBOM = "\uFEFF";
                var a = window.document.createElement('a');
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
                a.setAttribute('download', `entries-list_${competitionId}.csv`);
                window.document.body.appendChild(a);
                a.click();
                window.location.reload();
            }
            else {
                alert(data.message);
                return false;
            }
            // window.open(Constant.apiBasePath + 'exportuserData', 'text/html; charset=utf8').focus();
        }
        catch(error) {
           console.log(error);
        }
    }


    render() {
        let { DataList } = this.state;
        let entryList = [];

        if(DataList.length > 0) {
            entryList = DataList.map(el => {
                return <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div className="entry-item">
                            <h5>{ el.name }</h5>
                            <p>Draw Date: { el.endDate } { el.endTimeAMPM }</p>
                            <button type="button" className="btn btn-warning" onClick={ ()=> { this.exportData(el._id) } }>View Entries </button>
                        </div>
                    </div>
            });
        }
        return(

            <>
                <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Entry List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>

                <section className="wrap inr-wrap-tp">
                    <div className="container-fluid">
                        <div className="page-header">
                        <div className="d-inline-block">
                        <h3>Entry List</h3>
                            <div className="bdr-btm-heading"></div>
                        </div>
                            
                        </div>
                        <div className="row entry-list">
                            { entryList }
                        </div>
                    </div>
                </section>
            </>
        )
    }
}