import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';


export default class Footer extends Component {

    state = {
        email: "",
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    newsLetter = async (e) => {
        let email = this.state.email;
        let { data } = await Axios.post(Constant.apiBasePath + 'user/news-letter', { email });
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            this.setState({ "errorMessage": '' });
            setTimeout(() => { this.setState({ 'successMessage': "" }) }, 3000);
        }
        else {
            this.setState({ "errorMessage": data.message });
            this.setState({ 'successMessage': '' });
            return false;
        }
    }


    render() {
        return(
            <>
                <section class="wrap wrap-top0">
                    <div class="container-fluid">
                        <div class="Subscribe-wrap">
                            <div class="Subscribe-box">
                                <div class="row justify-content-center">
                                    <div class="col-md-12 col-lg-12 col-xl-12">
                                        <div class="fotr-media-item fotr-Subscribe">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-7">
                                                    <h4>Subscribe to our</h4>
                                                    <h3>Newsletter</h3>
                                                    <p>To be uploaded with all the latest trends and products</p>
                                                </div>
                                                <div class="col-md-6 col-lg-5">
                                                    <div class="foter-search">
                                                        <form class="navbar-form">
                                                            <div class="form-group">
                                                                <input type="email" id="email" class="form-control" placeholder="Email" onChange={this.handleChange}/>
                                                                <button type="button" class="btn btn-dark" onClick={ ()=> { this.newsLetter() } }>Join</button>
                                                                {
                                                                    (this.state.successMessage)
                                                                    ?
                                                                    <p className="text-success">{ this.state.successMessage }</p>
                                                                    :
                                                                    (this.state.errorMessage)
                                                                    ?
                                                                    <p className="text-danger"> { this.state.errorMessage }</p>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                <div class="fotr-media-item">
                                    <h3>Quick Links</h3>
                                    <ul class="list-unstyled fotr-menu">
                                        <li><Link to="/">Competitions</Link></li>
                                        <li><Link to="/our-winners">Our Winners</Link></li>
                                        <li><Link to ="/draws">Draws</Link></li>
                                        <li><Link to="/entry-list">Entry List</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                <div class="fotr-media-item">
                                    <h3>Others</h3>
                                    <ul class="list-unstyled fotr-menu">
                                        <li><Link to="/terms-and-conditions">Terms & Conditions </Link></li>
                                        <li><Link to="/payment-policy">Payment Policy </Link></li>
                                        <li><Link to="/terms-of-user">Terms of use</Link></li>
                                        <li><Link to="/disclaimer">Disclaimer</Link></li>
                                    </ul>
                                </div>
                            </div>
                        
                            <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-5">
                            <div class="fotr-media-item">
                                <h3>Main Links</h3>
                                <ul class="list-unstyled fotr-menu">
                                    <li><Link to="/about-us">Who we Are</Link></li>
                                    <li><Link to="/">How We Work</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                                
                        <div class="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <div class="fotr-media-item">
                                <div class="fotr-logo">
                                    <img src="images/logo.png"/>
                                </div>
                                <h3>Social Media</h3>
                                <div class="list-inline hdr-social-link">
                                    <ul class="list-inline">
                                        <li><a href="#"><i class="fa fa-instagram"></i> </a> </li>
                                        <li><a href="#"><i class="fa fa-facebook"></i> </a> </li>
                                        <li><a href="#"><img src="images/tictok.png"/> </a> </li>
                                    </ul>
                                </div>
                            </div>		
                        </div>
                        </div>
                    </div>
                    <section class="cpyrgt-wrap">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-7 col-md-7"><p>© 2023, <b>RO COMPETITION</b>  | All rights reserved.</p> </div>
                                <div class="col-sm-5  col-md-5 txt-rgt">
                                    <ul class="list-inline payment-icon d-flex justify-content-end">
                                        <li><a href="#"><img src="images/paypal.png"/></a></li>
                                        <li><a href="#"><img src="images/maestro.png"/></a></li>
                                        <li><a href="#"><img src="images/discover.png"/></a></li>
                                        <li><a href="#"><img src="images/visa.png"/></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </footer>
            </> 
        )
    }
}