import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';


export default class ProfileResetPassword extends Component {

    state = {
        password:"",
        confirmPassword:"",
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        // input form validation
        if(this.inputFieldValidation() == false){
            return false
        }
        let token = localStorage.getItem('loginDetails');


        let { data } = await Axios.patch(Constant.apiBasePath + 'user/setUserPassword', { password: this.state.password, confirmPassword: this.state.confirmPassword}, { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'successMessage': data.message });
            setTimeout(() => { window.location.href = '/profile' }, 3000);
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    inputFieldValidation = () => {
        let password = this.state.password;
        let confirmPassword = this.state.confirmPassword;
        if(password === '' || password === undefined) {
            this.setState({ 'errorMessage': 'Password required' });
            return false
        }
        else {
            this.setState({ 'errorMessage': '' });
        }

        if(confirmPassword === '' || confirmPassword === undefined) {
            this.setState({ 'errorMessage': 'confirm Password required' });
            return false
        }
        else {
            this.setState({ 'errorMessage': '' });
            return true
        }
    
    }

    
    render() {
        let { UserDetails, modal, password, confirmPassword} = this.state;

        return(
            <>
                <section className="inr-wrap">
                    <div className="container-fluid">
                        <div className="breadcrumb-heading">
                            <nav aria-label="breadcrumb">
                                <ol className="brdcrm breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/profile">My Account</Link></li>
                                    <li className="breadcrumb-item active">Edit Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="wrap user-dashboard">
                    <div className="container-fluid">
                        <div className="page-header border-0 pb-0">
                            <h2>Edit Profile</h2>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="profile_dashboard">
                                 
                                    <div className="account_dashboard">
                                        <div className="form-box">
                                            <form>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" id="password" onChange={ this.handleChange } defaultValue={ password } className="form-ctrl form-control" placeholder="Password*"/>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <input type="email" id="confirmPassword" onChange={ this.handleChange } value={ confirmPassword }  className="form-ctrl form-control" placeholder="Confirm Password*"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                                {/* <div className='row'>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <select className="form-ctrl form-control" id="countryId" onChange={ this.handleChange }>
                                                                <option id="0" value="0">Select Country</option>
                                                                { countryOptions }
                                                            </select>
                                                            <p className='text-danger'>{ this.state.errorSelectCountry }</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <select className="form-ctrl form-control" id="cityId" onChange={ this.handleChange }>
                                                                <option id="0" value="0">Select City</option>
                                                                { cityOptions }
                                                            </select>
                                                            <p className='text-danger'>{ this.state.errorSelectCity }</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                               
                                                <div className="row">
                                                    {
                                                        (this.state.successMessage)
                                                        ?
                                                        <p className='text-success'>{ this.state.successMessage }</p>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        (this.state.errorMessage)
                                                        ?
                                                        <p className='text-danger'>{ this.state.errorMessage }</p>
                                                        :
                                                        null
                                                    }

                                                    <div className="col-5 col-sm-3 col-md-3 col-lg-3">
                                                        <button type="submit" className="btn btn-warning btn-block btn-sumbit" onClick={ this.handleSubmit }>Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>

                  
                </section>
            </>
        )
    }
}