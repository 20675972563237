import React, { Component } from 'react';
import Constant from './Constant';
import Axios from 'axios';
import { Link } from 'react-router-dom';


export default class Profile extends Component {

    state = {
        UserDetails: '',
        successMessage: '',
        errorMessage: '',
        addressTableShow: false,
        billingInfo:[]
    }

    getProfileDetails = async () => {
        let token = localStorage.getItem('loginDetails');
        let { data } = await Axios.get(Constant.apiBasePath + 'user/getProfileDetails', { headers: { 'token': token }});
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'UserDetails': data.data,  billingInfo: data.data.billinginfos});
        }
        else {
            this.setState({ 'errorMessage': data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getProfileDetails();
    }
    
    render() {
        let { UserDetails, addressTableShow, billingInfo } = this.state;
        let profilePic = (UserDetails.profilePic) ? UserDetails.profilePic : '/images/user-pic.png';

        let firstName = '';
        let lastName = '';
        let email = '';
        let companyName = '';
        let street = '';
        let postCode = '';
        let mobile = '';
        let countryName ='';
        let cityName = '';
        let buttonHeading = 'Add Address';
        let addressId = "";

        let billingList = [];

        if(billingInfo.length > 0) {
            billingList = billingInfo.map(address => {
                return  <div className="biling-item alert alert-light fade show" role="alert" key={address._id}>
                                <ul className="list-inline d-flex flex-wrap bill-info-user">
                                <li>
                                    <h6>First Name</h6>
                                    <p>{ address.firstName }</p>
                                </li>
                                <li>
                                    <h6>Last Name</h6>
                                    <p>{ address.lastName }</p>
                                </li>
                                <li>
                                    <h6>Street</h6>
                                    <p>{ address.street }</p>
                                </li>
                                <li>
                                    <h6>Town and City</h6>
                                    <p>{ address.cityName }</p>
                                </li>
                                <li>
                                    <h6>Country</h6>
                                    <p>{ address.countryName }</p>
                                </li>
                            </ul>
                            <ul className="list-inline d-flex flex-wrap bill-info-user mb-0">
                                {/* <li>
                                    <h6>Region</h6>
                                    <p>Indian</p>
                                </li> */}
                                <li>
                                    <h6>Post Code</h6>
                                    <p>{ address.postCode }</p>
                                </li>
                                <li>
                                    <h6>Email ID </h6>
                                    <p>{ address.email }</p>
                                </li>
                                <li>
                                    <div className="wishlist-delete d-flex">
                                        <div className="pencil">
                                            <Link to={`/update-billing-info/${ address._id }`} className="btn btn-light" href="#"><img src="/images/pencil.png"/></Link>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                  
                                </li>
                            </ul>
                        </div>
            });
        }


        if(UserDetails.userAddressData) {
            let userAddress = UserDetails.userAddressData[0];
            if(userAddress) {
                addressTableShow = true;
                addressId = userAddress._id;
                firstName = userAddress.firstName;
                lastName = userAddress.lastName;
                email = userAddress.email;
                companyName = userAddress.companyName;
                street = userAddress.street;
                postCode = userAddress.postCode;
                mobile = userAddress.mobile;
                countryName = userAddress.countryName;
                cityName = userAddress.cityName;
                buttonHeading = 'Update Address';
            }
        }
       

        return (
          <>
            <section className="inr-wrap">
              <div className="container-fluid">
                <div className="breadcrumb-heading">
                  <nav aria-label="breadcrumb">
                    <ol className="brdcrm breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">My Account</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </section>
            <section className="wrap user-dashboard">
              <div className="container-fluid">
                <div className="page-header border-0 pb-0">
                  <div className="d-inline-block">
                    <h2>My Account</h2>
                    <div className="bdr-btm-heading"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="profile_dashboard">
                      <div className="row">
                        <div className="col-9 col-sm-9">
                          <div className="name_info media">
                            <div className="media-left">
                              <div className="profile-pic">
                                <div className="upload-doc-item">
                                  <div className="image-upload">
                                    {/* <input type="file" name="" id="logo" onchange="fileValue(this)"/> */}
                                    <label
                                      for="logo"
                                      className="upload-field"
                                      id="file-label"
                                    >
                                      <div className="file-thumbnail">
                                        <img
                                          id="file-image-preview"
                                          src={profilePic}
                                          alt=""
                                        />
                                        <h6 id="filename"></h6>
                                        {/* <div className="edit-pic"><i className="fa fa-pencil"></i></div> */}
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="media-body">
                              <h4>{UserDetails.name}</h4>
                              {/* <p>User ID: { UserDetails._id }</p> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-3 col-sm-3">
                          <div className="text-end">
                            <Link
                              className="btn btn-sm btn-warning"
                              to="/edit-profile"
                            >
                              <i className="fa fa-pencil me-2"></i> Edit
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="form-box form-disabled">
                        <form action="#">
                          <div className="title mb-3">
                            <h5>Profile Details </h5>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-ctrl form-control"
                                  placeholder=""
                                  value={UserDetails.firstName+" "+UserDetails.lastname}
                                  disabled
                                />
                              </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">  
                                                    <div className="form-group">
                                                        <input type="text" className="form-ctrl form-control" placeholder="" value="+011  245665 2356" disabled/>
                                                        <button type="button" className="Change-btn btn btn-warning">Change</button>
                                                    </div>
                                                </div> */}

                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-ctrl form-control"
                                  placeholder=""
                                  value={UserDetails.email}
                                  disabled
                                />
                                {/* <button type="button" className="Change-btn btn btn-warning">Change</button> */}
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-ctrl form-control"
                                  placeholder=""
                                  value={UserDetails.countryName}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-ctrl form-control"
                                  placeholder=""
                                  value={UserDetails.cityName}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="title mb-3 mt-4">
                            <h5>My Addresses</h5>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <div className="col-sm-12 col-md-12 col-lg-12  col-xl-12">
                                <div className="summary box-height500">
                                  <div className="row">
                                    <div className="col-8 col-sm-7 col-md-7 col-lg-7">
                                      <h3>Added billing info</h3>
                                    </div>
                                    <div className="col-4 col-sm-5 col-md-5 col-lg-5 text-end">
                                      <h3>
                                        <Link
                                          to="/add-new-billing-info"
                                          className="btn btn-dark"
                                        >
                                          Add New
                                        </Link>
                                      </h3>
                                    </div>
                                  </div>

                                  {billingList}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        );
    }
}