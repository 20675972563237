import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constant from './Constant';
import Axios from 'axios';
import $ from 'jquery';

export default class FAQs extends Component {

    state = {
        DataList: [],
        successMessage: '',
        errorMessage: ''
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    // get data list

    getData = async () => {
        let { data } = await Axios.get(Constant.apiBasePath + 'question-answer');
        if(data.status === Constant.statusSuccess) {
            this.setState({ 'DataList': data.data });
        }
        else {
            // this.setState({ "errorMessage": data.message });
            return false;
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const { DataList } = this.state; 

        function collapseDiv(id) {
            let customId = "#" + id;
            let activeFlag = $(customId).attr('aria-expanded');
            
            if(activeFlag !== 'false') {
              $(customId).removeClass();
              $(customId).addClass("accordion-button collapsed");
              $(customId).attr('aria-expanded', 'false');
              $('#collapse_' + id).removeClass();
              $('#collapse_' + id).addClass('accordion-collapse collapse');
            }
            else {
              $(customId).removeClass();
              $(customId).addClass("accordion-button");
              $(customId).attr('aria-expanded', '');
              $(customId).attr('aria-expanded', 'true');
              $('#collapse_' + id).removeClass();
              $('#collapse_' + id).addClass('accordion-collapse collapse show');
            }
        }

        let items = [];
        if(DataList.length > 0) {
            items = DataList.map(el => {
              let id = '#' + el._id;
              return <div className="accordion-item">
                          <h2 className="accordion-header" id={ id }>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={ id } aria-expanded="false" aria-controls={el._id} id={el._id} onClick={ ()=> { collapseDiv(el._id) } }>
                            { el.question }
                            </button>
                          </h2>
                          <div id={`collapse_${el._id}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <p>{ el.answer }</p>
                              </div>
                          </div>
                      </div>
            })
        }

        return(
              <section className="wrap-40">
                  <div className="container-fluid">
                      <div className="page-header border-0 pb-0 mb-2">
                          <h3>Frequently asked questions</h3>
                          <div className="bdr-btm-heading">
                          </div>
                      </div>
                      <div className="faq-panel inrpage-faq mb-5">
                          <div className="accordion" id="accordionExample">
                              { items }
                          </div>
                      </div>
                  </div>
              </section>
        )
    }
}