module.exports = {
    'statusSuccess': 'Success',
	'statusFailure': 'Failure',
	'somethingError': 'Something Error',
	'perPage': 10,
	'tokenExpired': 'Your access token has been expired.',
	'userExist': 'User already exist',

	// local path

	// 'apiBasePath': 'http://localhost:8020/api/',

    // server path

	// 'apiBasePath': 'http://13.234.187.136:8020/api/',
	   'apiBasePath': 'https://ro.b2cinfohosting.in/api/' 

}